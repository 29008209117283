<template>
    <div class="fill-info-container">
        <div class="fill-info-title">Please fill in company info</div>
        <div class="fill-info-title-hint">so that we can help you find musicians that cater to your needs.</div>
        
        <div class="fill-info-card">
            <div style="color:#CF80EB;font-size: 18px;padding-bottom: 24px">Account Info</div>
            <div style="position: relative;">
                <div style="display: inline-block; position: relative;">
                    <img class="fill-info-avatar" :src="avatarURL"/>
                    <img src="../assets/vertified-icon.svg" style="position: absolute; width: 24px; height: 24px; bottom: 0; right: 0;" 
                    v-show="hasEnterpriseLicense"/>
                </div>
                
                <div class="fill-info-my-info">
                    <div style="color: rgba(255, 255, 255, 0.60);font-size: 14px;line-height: 22px;">
                        User Name
                    </div>
                    <div style="color: #FFF;font-size: 16px;line-height: 22px;">
                        {{ userName }}
                    </div>
                </div>
                
                <div class="fill-info-my-info">
                    <div style="color: rgba(255, 255, 255, 0.60);font-size: 14px;line-height: 22px;">
                        Email
                    </div>
                    <div style="color: #FFF;font-size: 16px;line-height: 22px;">
                        {{ email }}
                    </div>
                </div>
            </div>
        </div>
        

        <div class="fill-info-card">
            <div style="color:#CF80EB;font-size: 18px;padding-bottom: 24px">Enterprise Certificate</div>
            
            <button class="fill-info-import-file" @click="{showUploadEnterpriseCertificate = true; a += 1;}">
                {{ certificateFilename ? "Manage File" : "Import File"}}
            </button>
            <div style="font-size: 14px;color: rgba(255, 255, 255, 0.60);">
                Import enterprise license to quickly fill in, and get 
                <img src="../assets/vertified-icon.svg" style="width: 24px; height: 24px; margin: 0 2px;">
                verified identity to build trust with musicians.
            </div>
            
            <div class="fill-info-card-name">Enterprise Name<span class="required-item">*</span></div>
            <input v-model="enterpriseName" type="text"
            class="fill-info-card-input" placeholder="Enter or auto-identify"/>
            
            <div class="fill-info-card-name">Contact<span class="required-item">*</span></div>
            <input v-model="contact"
            class="fill-info-card-input" placeholder="Enter or auto-identify"/>
            
            <div class="fill-info-card-name">Business Activity</div>
            <div style="width: 540px;">
                <textarea v-model="businessActivity"
                class="fill-info-card-textarea" style="width: 100%; height: 100px;" 
                maxlength="100" placeholder="Enter or auto-identify"></textarea>
                <div class="fill-info-card-input-pre">{{ businessActivity.length }} / 100</div>
            </div>
            
            <div class="fill-info-card-name">Address</div>
            <div style="width: 540px;">
                <textarea v-model="address"
                class="fill-info-card-textarea" style="width: 100%; height: 100px;"
                maxlength="100" placeholder="Enter or auto-identify"></textarea>
                <div class="fill-info-card-input-pre">{{ address.length }} / 100</div>
            </div>
        </div>

        
        <div class="fill-info-card">
            <div style="color:#CF80EB;font-size: 18px;padding-bottom: 24px">Enterprise Info</div>
            
            <button class="fill-info-import-file" @click="{showUploadEnterpriseInfo = true; a += 1;}">
                {{ infoFilename ? "Manage File" : "Import File"}}
            </button>
            <div style="font-size: 14px;color: rgba(255, 255, 255, 0.60);">Import file to quickly fill in.</div>
            
            <div class="fill-info-card-name">Role<span class="required-item">*</span></div>
            <div>
                <div 
                v-for="(item, index) in roles" 
                :key="index" 
                :class="['fill-info-card-role', {'fill-info-card-role-choose': item.isChosen}]"
                @click="toggleRole(item.value, false)">
                    {{ item.value }}
                </div>
                <!-- <div class="fill-info-card-role fill-info-card-role-choose">Performance Host</div>
                <div class="fill-info-card-role">Crew</div>
                <div class="fill-info-card-role">Site provider</div>
                <div class="fill-info-card-role">Music festival host</div>
                <div class="fill-info-card-role">Night club</div>
                <div class="fill-info-card-role">Live house</div> -->
            </div>
            
            <div class="fill-info-card-name">Founder Info</div>
            <div style="width: 540px;">
                <textarea v-model="founderInfo"
                class="fill-info-card-textarea" style="width: 100%; height: 100px;"
                maxlength="100" placeholder="Enter or auto-identify"></textarea>
                <div class="fill-info-card-input-pre">{{ founderInfo.length }} / 100</div>
            </div>
            
            <div class="fill-info-card-name">Event-hosting Cases</div>
            <div style="width: 540px;">
                <textarea v-model="eventHostingCases"
                class="fill-info-card-textarea" style="width: 100%; height: 100px;"
                maxlength="100" placeholder="Enter or auto-identify"></textarea>
                <div class="fill-info-card-input-pre">{{ eventHostingCases.length }} / 100</div>
            </div>
        </div>
        
        <div style="display: flex; justify-content: center; margin-bottom: 48px;"
        @click="updateEnterpriseInfo()">
            <button class="fill-info-import-file" 
            :class="{'submit-disable': submitDisable }">{{ this.hasUpdated ? "Save Changes" : "Confirm and Summit"}}</button>
        </div>

        <!-- 上传企业凭证 -->
        <div class="upload-file" v-show="showUploadEnterpriseCertificate">
            <div class="upload-file-container">
                <img class="close-button" src="../assets/Close.svg"
                @click="showUploadEnterpriseCertificate = false"/>

                <div class="upload-title">Upload File</div>
                
                <div class="upload-list-container">
                    <a-upload-dragger
                        v-model:fileList="certificateFileList"
                        name="certificateFiles"
                        accept=".pdf"
                        :maxCount="1"
                        :customRequest="customUploadFile"
                        :multiple="true"
                        :progress="progress"
                        v-if="a"
                    >
                        <div style="text-align: center;"><img src="../assets/inbox.svg" style="width: 48px; height: 48px;"/></div>

                        <div style="margin-top: 20px;">
                            <p class="upload-text">Click or drag file to this area</p>
                            <p class="upload-hint">Support pdf, 1 file</p>
                        </div>

                        <template #itemRender="{file, actions}">
                            <div :class="['upload-list-item', 
                                          {'upload-list-item-error': file.status === 'error'}]">
                                <img src="../assets/file-pdf.svg" 
                                :class="{'upload-list-item-unsuccessful': file.status != 'done'}"
                                style="width: 48px; height: 48px"/>

                                <span :class="['upload-list-item-name',
                                                {'upload-list-item-name-error': file.status === 'error'}]">
                                    {{ file.name }}
                                </span>

                                <span class="upload-list-item-progress-format" v-show="file.status === 'uploading'">{{ file.percent }}%</span>
                                <span class="upload-list-item-hint-error" v-show="file.status === 'error'">Upload Error</span>
                                
                                <div class="upload-list-item-actions" @click="actions.remove">
                                    <img src="../assets/delete-icon.svg" style="width: 14px; height: 14px;"/>
                                </div>
                                
                                <div class="upload-list-item-progress" v-show="file.status === 'uploading'" :style="{width: file.percent + '%'}"></div>
                            </div>
                        </template>
                    </a-upload-dragger>
                </div>

                <div class="upload-import-container">
                    <button class="upload-import"
                    :class="{'btn-disable': !isOneValidCertficateFile}" 
                    @click="importFile(true, isOneValidCertficateFile)">Import</button>
                </div>
            </div>
        </div>

        <!-- 上传企业信息 -->
        <div class="upload-file" v-show="showUploadEnterpriseInfo">
            <div class="upload-file-container">
                <img class="close-button" src="../assets/Close.svg"
                @click="showUploadEnterpriseInfo = false"/>

                <div class="upload-title">Upload File</div>
                
                <div class="upload-list-container">
                    <a-upload-dragger
                        v-model:fileList="infoFileList"
                        name="infoFiles"
                        accept=".pdf"
                        :maxCount="1"
                        :customRequest="customUploadFile"
                        :multiple="true"
                        :progress="progress"
                        v-if="a"
                    >
                        <div style="text-align: center;"><img src="../assets/inbox.svg" style="width: 48px; height: 48px;"/></div>

                        <div style="margin-top: 20px;">
                            <p class="upload-text">Click or drag file to this area</p>
                            <p class="upload-hint">Support pdf, 1 file</p>
                        </div>

                        <template #itemRender="{file, actions}">
                            <div :class="['upload-list-item', 
                                          {'upload-list-item-error': file.status === 'error'}]">
                                <img src="../assets/file-pdf.svg" 
                                :class="{'upload-list-item-unsuccessful': file.status != 'done'}"
                                style="width: 48px; height: 48px"/>

                                <span :class="['upload-list-item-name',
                                                {'upload-list-item-name-error': file.status === 'error'}]">
                                    {{ file.name }}
                                </span>

                                <span class="upload-list-item-progress-format" v-show="file.status === 'uploading'">{{ file.percent }}%</span>
                                <span class="upload-list-item-hint-error" v-show="file.status === 'error'">Upload Error</span>
                                
                                <div class="upload-list-item-actions" @click="actions.remove">
                                    <img src="../assets/delete-icon.svg" style="width: 14px; height: 14px;"/>
                                </div>
                                
                                <div class="upload-list-item-progress" v-show="file.status === 'uploading'" :style="{width: file.percent + '%'}"></div>
                            </div>
                        </template>
                    </a-upload-dragger>
                </div>

                <div class="upload-import-container">
                    <button class="upload-import"
                    :class="{'btn-disable': !isOneValidInfoFile}" 
                    @click="importFile(false, isOneValidInfoFile)">Import</button>
                </div>
            </div>
        </div>

        <!-- 文件解析中 -->
        <div class="analyse-file" v-show="showAnalyzingFile">
            <div class="analyse-file-container">
                <img src="../assets/loading.gif" style="width: 100%;"/>
                <div style="margin-top: 48px; margin-bottom: 77px;">
                    <div style="text-align: center; color: white; font-size: 21px; font-family: PingFang SC; font-weight: 400;">Analyzing File...</div>
                    <div style="margin-top: 20px; text-align: center; color: #CF80EB; font-size: 18px; font-family: PingFang SC; font-weight: 400;">{{ analyseProgress }}%</div>
                </div>
            </div>
        </div>

        <!-- 企业凭证解析完成 -->
        <div class="analysis-result" v-show="showEnterpriseCertificateAnalysis">
            <div class="analysis-result-container">
                <img class="close-button" src="../assets/Close.svg"
                @click="showEnterpriseCertificateAnalysis = false"/>
                
                <div style="display: flex; justify-content: center; align-items: center;">
                    <img src="../assets/login-circle.svg" style="width: 32px; height: 32px; "/>
                    <span style="margin-left: 16px; color: white; font-size: 21px; font-family: PingFang SC;">Analysis Done</span>
                </div>

                <div class="fill-info-card-name">Enterprise Name</div>
                <input v-model="enterpriseNameTemp"
                class="fill-info-card-input" placeholder="Enter or auto-identify"
                style="width: 100%;"/>

                <div class="fill-info-card-name" style="padding-top: 24px;">Contact</div>
                <input v-model="contactTemp"
                class="fill-info-card-input" placeholder="Enter or auto-identify"
                style="width: 100%;"/>

                <div class="fill-info-card-name" style="padding-top: 24px;">Business Activity</div>
                <div style="width: 100%;">
                    <textarea v-model="businessActivityTemp"
                    class="fill-info-card-textarea" style="width: 100%; height: 80px;" 
                    maxlength="100" placeholder="Enter or auto-identify"></textarea>
                    <div class="fill-info-card-input-pre">{{ businessActivityTemp.length }} / 100</div>
                </div>

                <div class="fill-info-card-name" style="padding-top: 0;">Address</div>
                <div style="width: 100%;">
                    <textarea v-model="addressTemp"
                    class="fill-info-card-textarea" style="width: 100%; height: 80px;" 
                    maxlength="100" placeholder="Enter or auto-identify"></textarea>
                    <div class="fill-info-card-input-pre">{{ addressTemp.length }} / 100</div>
                </div>

                <div style="display: flex; justify-content: center;">
                    <button @click="fillEnterpriseCertificate()"
                    class="fill-info-import-file" style="width: 200px; margin-top: 20px">Confirm and Fill in</button>
                </div>
            </div>
        </div>

        <!-- 企业信息解析完成 -->
        <div class="analysis-result" v-show="showEnterpriseInfoAnalysis">
            <div class="analysis-result-container">
                <img class="close-button" src="../assets/Close.svg"
                @click="showEnterpriseInfoAnalysis = false"/>

                
                <div style="display: flex; justify-content: center; align-items: center;">
                    <img src="../assets/login-circle.svg" style="width: 32px; height: 32px; "/>
                    <span style="margin-left: 16px; color: white; font-size: 21px; font-family: PingFang SC;">Analysis Done</span>
                </div>

                <div class="fill-info-card-name" style="padding-top: 24px; padding-bottom: 0;">Role<span class="required-item">*</span></div>
                <div style="line-height: 48px;">
                    <div 
                    v-for="(item, index) in rolesTemp" 
                    :key="index" 
                    :class="['fill-info-card-role', {'fill-info-card-role-choose': item.isChosen}]"
                    @click="toggleRole(item.value, true)">
                        {{ item.value }}
                    </div>
                </div>

                <div class="fill-info-card-name" style="padding-top: 24px;">Founder Info</div>
                <div style="width: 100%;">
                    <textarea v-model="founderInfoTemp"
                    class="fill-info-card-textarea" style="width: 100%; height: 100px;"
                    maxlength="100" placeholder="Enter or auto-identify"></textarea>
                    <div class="fill-info-card-input-pre">{{ founderInfoTemp.length }} / 100</div>
                </div>

                <div class="fill-info-card-name" style="padding-top: 0;">Event-hosting Cases</div>
                <div style="width: 100%;">
                    <textarea v-model="eventHostingCasesTemp"
                    class="fill-info-card-textarea" style="width: 100%; height: 100px;"
                    maxlength="100" placeholder="Enter or auto-identify"></textarea>
                    <div class="fill-info-card-input-pre">{{ eventHostingCasesTemp.length }} / 100</div>
                </div>

                <div style="display: flex; justify-content: center;">
                    <button @click="fillEnterpriseInfo()"
                    class="fill-info-import-file" style="width: 200px; margin-top: 20px">Confirm and Fill in</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { UpdateUserInfoRequestParams } from "../functions/updateUserInfoParams.js";
    import { QueryUserInfoRequestParams, QUERY_USER_INFO_TYPE } from "@/functions/queryUserInfoParams.js";
    import { user, API_HOST } from "../js/global.js"
    import { STORAGE_NAME, getFilePath, getFolderPath, getDownloadURLByPath, uploadFile} from "../functions/fileUpDownload.js";
    import { FileRecognizeRequestParams, FILE_RECOGNIZE_TYPE } from "../functions/fileRecognizeParams.js";
    import { RESP_CODE, ENTERPRISE_ROLE } from "../functions/commonUtil.js";

    export default {
        name: "FillCompanyInfoPage",
        data() {
            return {
                a: 0,  // 用于重新渲染a-upload控件
                hasEnterpriseLicense: false,  // 用于渲染认证图标
                userName: "",  // 用户名
                email: "",  // 邮箱
                avatarURL: "",  // 头像地址
                enterpriseName: "",
                enterpriseNameOnServer: "",
                enterpriseNameTemp: "This is an example of a filled text area.",
                contact: "",
                contactTemp: "This is an example of a filled text area.",
                contactOnServer: "",
                businessActivity: "",
                businessActivityTemp: "A design system for enterprise-level products. Create an efficient and enjoyable work experience.",
                businessActivityOnServer: "",
                address: "",
                addressTemp: "A design system for enterprise-level products. Create an efficient and enjoyable work experience.",
                addressOnServer: "",
                founderInfo: "",
                founderInfoTemp: "A design system for enterprise-level products. Create an efficient and enjoyable work experience.",
                founderInfoOnServer: "",
                eventHostingCases: "",
                eventHostingCasesTemp: "A design system for enterprise-level products. Create an efficient and enjoyable work experience.",
                eventHostingCasesOnServer: "",
                // roles: [
                //     {
                //         value: "Performance Host",
                //         isChosen: false,
                //     },
                //     {
                //         value: "Crew",
                //         isChosen: false,
                //     },
                //     {
                //         value: "Site provider",
                //         isChosen: false,
                //     },
                //     {
                //         value: "Music festival host",
                //         isChosen: false,
                //     },
                //     {
                //         value: "Night club",
                //         isChosen: false,
                //     },
                //     {
                //         value: "Live house",
                //         isChosen: false,
                //     },
                // ],
                roles: [],  // 从commonUtils.js中获取
                rolesOnServer: [],
                roleIndexMap: {},  // 角色映射到roles数组的索引
                // rolesTemp: [
                //     {
                //         value: "Performance Host",
                //         isChosen: false,
                //     },
                //     {
                //         value: "Crew",
                //         isChosen: false,
                //     },
                //     {
                //         value: "Site provider",
                //         isChosen: true,
                //     },
                //     {
                //         value: "Music festival host",
                //         isChosen: false,
                //     },
                //     {
                //         value: "Night club",
                //         isChosen: false,
                //     },
                //     {
                //         value: "Live house",
                //         isChosen: true,
                //     },
                // ],
                rolesTemp: [],
                // certificateFileList: [
                //     {
                //         uid: '-1',
                //         name: 'xxx.pdf',
                //         status: 'done',
                //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     },
                //     {
                //         uid: '-2',
                //         name: 'xxx.pdf',
                //         status: 'uploading',
                //         percent: 20,
                //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     },
                //     {
                //         uid: '-3',
                //         name: 'xxx.pdf',
                //         status: 'error',
                //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     },
                //     {
                //         uid: '-4',
                //         name: 'xxx.pdf',
                //         status: 'done',
                //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     }
                // ],
                certificateFileList: [],
                certificateFileOnServer: null,
                // infoFileList: [
                //     {
                //         uid: '-1',
                //         name: 'xxx.pdf',
                //         status: 'uploading',
                //         percent: 20,
                //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     },
                //     {
                //         uid: '-2',
                //         name: 'xxx.pdf',
                //         status: 'done',
                //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     },
                //     {
                //         uid: '-3',
                //         name: 'xxx.pdf',
                //         status: 'error',
                //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     }
                // ],
                infoFileList: [],
                infoFileOnServer: null,
                progress: {
                    strokeColor: {
                        from: "#CF80EB",
                        to: "#8549FD",
                    },
                    strokeWidth: 3,
                    status: 'normal',
                },

                analyseProgress: 0,

                showUploadEnterpriseCertificate: false,
                showUploadEnterpriseInfo: false,
                showAnalyzingFile: false,
                showEnterpriseCertificateAnalysis: false,
                showEnterpriseInfoAnalysis: false,
                hasUpdated: false,  // 非第一次上传，该值为true
                hasChanged: false,  // 用于监听表单内容是否有变（有变则按钮可点击）
            }
        },
        watch: {
            changeData: {
                handler() {
                    if (this.enterpriseName != this.enterpriseNameOnServer ||
                        this.contact != this.contactOnServer ||
                        this.businessActivity != this.businessActivityOnServer ||
                        this.address != this.addressOnServer ||
                        JSON.stringify(this.roles) != JSON.stringify(this.rolesOnServer) ||
                        this.founderInfo != this.founderInfoOnServer ||
                        this.eventHostingCases != this.eventHostingCasesOnServer ||
                        this.certificateFilename != this.certificateFileOnServer ||
                        this.infoFilename != this.infoFileOnServer) {
                            this.hasChanged = true;
                    }
                },
                deep: true
            }
        },
        computed: {
            changeData() {
                const {enterpriseName, contact, businessActivity, address, roles, founderInfo, eventHostingCases, certificateFileList, infoFileList} = this;
                return {enterpriseName, contact, businessActivity, address, roles, founderInfo, eventHostingCases, certificateFileList, infoFileList}
            },

            hasChosenRoles() {
                for(let i = 0; i < this.roles.length; i++) {
                    if (this.roles[i].isChosen == true) {
                        return true;
                    }
                }
                return false;
            },
            enterpriseRoles() {
                const chosenRoles = [];
                for(let i = 0; i < this.roles.length; i++) {
                    if (this.roles[i].isChosen == true) {
                        chosenRoles.push(this.roles[i].value);
                    }
                }
                return chosenRoles;
            },
            submitDisable() {
                return (!this.enterpriseName) || (!this.contact) || (!this.hasChosenRoles) || (!this.hasChanged);
            },

            isOneValidCertficateFile() {
                var count = 0;
                for(let i = 0; i < this.certificateFileList.length; i++) {
                    if (this.certificateFileList[i].status == "done") {
                        count += 1;
                    }
                }
                if (count == 1) {
                    return true;
                }
                return false;
            },

            isOneValidInfoFile() {
                var count = 0;
                for(let i = 0; i < this.infoFileList.length; i++) {
                    if (this.infoFileList[i].status == "done") {
                        count += 1;
                    }
                }
                if (count == 1) {
                    return true;
                }
                return false;
            },

            certificateFilename() {
                var filename = null;
                for (let i = 0; i < this.certificateFileList.length; i++) {
                    if (this.certificateFileList[i].status == "done") {
                        filename = this.certificateFileList[i].utf8Name;
                        break;
                    }
                }
                return filename;
            },

            infoFilename() {
                var filename = null;
                for (let i = 0; i < this.infoFileList.length; i++) {
                    if (this.infoFileList[i].status == "done") {
                        filename = this.infoFileList[i].utf8Name;
                        break;
                    }
                }
                return filename;
            }
        },
        methods: {

            setTopBarVisible(visible, refreshUserInfo=false, showExit=false) {
                this.$emit('setTopBarVisible', visible, refreshUserInfo, showExit);
            },

            // 获取主办方角色候选项
            getRoles() {
                this.roles = [];
                let i = 0;
                for (let key in ENTERPRISE_ROLE) {
                    this.roles.push({value: ENTERPRISE_ROLE[key], isChosen: false});
                    this.rolesTemp.push({value: ENTERPRISE_ROLE[key], isChosen: false});
                    this.roleIndexMap[ENTERPRISE_ROLE[key]] = i;
                    i += 1;
                }
            },

            toggleRole(value, isTemp) {
                let roles;
                if (isTemp) {
                    roles = this.rolesTemp;
                }
                else {
                    roles = this.roles;
                }
                for(var i = 0; i < roles.length; i++) {
                    if (roles[i].value == value) {
                        roles[i].isChosen = !roles[i].isChosen
                    }
                }
            },

            importFile(isEnterPriseCertficate, isOneValidFile) {

                if (!isOneValidFile) {
                    return;
                }
                
                if (isEnterPriseCertficate) {  // 解析企业凭证
                    const fileRecognizeReq = new FileRecognizeRequestParams(user.uid, this.certificateFilename, FILE_RECOGNIZE_TYPE.COMPANY_CERT);
                    const cgiUrl = API_HOST + '/fileRecognize';
                    const body = JSON.stringify(fileRecognizeReq);
                    fetch(cgiUrl, {
                        method: 'POST',
                        body: body,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then((response) => {
                        console.log("结果：", response);
                        if (!response.ok) {
                            console.log(cgiUrl, '失败，错误码：' + response.status);
                            return;
                        }
                        return response.json();
                    })
                    .then((responseData) => {
                        console.log('数据:', responseData);
                        if (responseData.code == RESP_CODE.SUCCESS) {
                            this.enterpriseNameTemp = responseData.enterpriseName;
                            this.contactTemp = responseData.contact;
                            this.businessActivityTemp = responseData.bussinessArea;
                            this.addressTemp = responseData.address;

                            this.showUploadEnterpriseCertificate = false;
                            this.analyseProgress = 0;
                            this.showAnalyzingFile = true;
                            let progress = 0;
                            const increment = 100 / (3000 / 10);  // 等待1秒(0->100)，每10毫秒增加的值
                            const intervalId = setInterval(() => {
                                if (progress < 100) {
                                    progress += increment;
                                    this.analyseProgress = Math.round(progress);
                                }
                                else {
                                    this.analyseProgress = 100;
                                    clearInterval(intervalId);
                                    this.showAnalyzingFile = false;
                                    this.showEnterpriseCertificateAnalysis = true;
                                }
                            }, 10);
                        }
                    })
                    .catch((error) => {
                        console.log(cgiUrl, "失败：", error);
                    });
                }

                else {  // 解析主办方信息
                    const fileRecognizeReq = new FileRecognizeRequestParams(user.uid, this.infoFilename, FILE_RECOGNIZE_TYPE.COMPANY_INFO);
                    const cgiUrl = API_HOST + '/fileRecognize';
                    const body = JSON.stringify(fileRecognizeReq);
                    fetch(cgiUrl, {
                        method: 'POST',
                        body: body,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then((response) => {
                        console.log("结果：", response);
                        if (!response.ok) {
                            console.log(cgiUrl, '失败，错误码：' + response.status);
                            return;
                        }
                        return response.json();
                    })
                    .then((responseData) => {
                        console.log('数据:', responseData);
                        if (responseData.code == RESP_CODE.SUCCESS) {
                            for (let i = 0; i < this.rolesTemp.length; i++) {  // 刷新rolesTemp选中的状态
                                this.rolesTemp[i].isChosen = false;
                            }
                            const roles = responseData.enterpriseRole;
                            for (let i = 0; i < roles.length; i++) {
                                this.rolesTemp[this.roleIndexMap[roles[i]]].isChosen = true;
                            }
                            this.founderInfoTemp = responseData.founderInfo;
                            this.eventHostingCasesTemp = responseData.eventHosting;
                            
                            this.showUploadEnterpriseInfo = false;
                            this.analyseProgress = 0;
                            this.showAnalyzingFile = true;
                            let progress = 0;
                            const increment = 100 / (3000 / 10);  // 等待3秒(0->100)，每10毫秒增加的值
                            const intervalId = setInterval(() => {
                                if (progress < 100) {
                                    progress += increment;
                                    this.analyseProgress = Math.round(progress);
                                }
                                else {
                                    this.analyseProgress = 100;
                                    clearInterval(intervalId);
                                    this.showAnalyzingFile = false;
                                    this.showEnterpriseInfoAnalysis = true;
                                }
                            }, 10);
                        }
                    })
                    .catch((error) => {
                        console.log(cgiUrl, "失败：", error);
                    });
                }
            },

            fillEnterpriseCertificate() {
                this.enterpriseName = this.enterpriseNameTemp
                this.contact = this.contactTemp
                this.businessActivity = this.businessActivityTemp
                this.address = this.addressTemp
                this.showEnterpriseCertificateAnalysis = false
            },

            fillEnterpriseInfo() {
                let _ = require('lodash')
                this.roles = _.cloneDeep(this.rolesTemp)
                this.founderInfo = this.founderInfoTemp
                this.eventHostingCases = this.eventHostingCasesTemp
                this.showEnterpriseInfoAnalysis = false
            },

            // 查询企业信息
            queryEnterpriseInfo() {
                const queryUserInfoReq = new QueryUserInfoRequestParams(user.uid, QUERY_USER_INFO_TYPE.ALL_INFO);
                const cgiUrl = API_HOST + '/queryUserInfo';
                const body = JSON.stringify(queryUserInfoReq);
                fetch(cgiUrl, {
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    console.log("结果：", response);
                    if (!response.ok) {
                        console.log(cgiUrl, '失败，错误码：' + response.status);
                        return;
                    }
                    return response.json(); // 解析JSON响应;
                })
                .then((responseData) => {
                    console.log('数据:', responseData);
                    if (responseData) {
                        const path = getFilePath(user.uid, STORAGE_NAME.AVATAR, responseData.avatar);
                        getDownloadURLByPath(path).then((url) => {
                            this.avatarURL = url;
                        });
                        this.userName = responseData.name;
                        this.email = responseData.email;
                        this.enterpriseName = responseData.enterprise_name != null ? responseData.enterprise_name : "";
                        this.enterpriseNameOnServer = this.enterpriseName;
                        this.contact = responseData.contact != null ? responseData.contact : "";
                        this.contactOnServer = this.contact;
                        this.businessActivity = responseData.bussiness_area != null ? responseData.bussiness_area : "";
                        this.businessActivityOnServer = this.businessActivity;
                        this.address = responseData.address != null ? responseData.address : "";
                        this.addressOnServer = this.address;
                        // this.founding_time = null;
                        if (responseData.enterprise_role) {
                            for(let i = 0; i < responseData.enterprise_role.length; i++) {
                                let k = responseData.enterprise_role[i];
                                this.roles[this.roleIndexMap[k]].isChosen = true;
                            }
                        }
                        this.rolesOnServer = JSON.parse(JSON.stringify(this.roles));
                        this.founderInfo = responseData.founder_info != null ? responseData.founder_info : "";
                        this.founderInfoOnServer = this.founderInfo;
                        this.eventHostingCases = responseData.event_hosting != null ? responseData.event_hosting : "";
                        this.eventHostingCasesOnServer = this.eventHostingCases;
                        if (responseData.cert_file) {
                            this.hasEnterpriseLicense = true;
                            this.certificateFileList.push(
                                {
                                    uid: '1',
                                    utf8Name: responseData.cert_file,
                                    name: decodeURIComponent(responseData.cert_file),
                                    status: 'done',
                                }
                            );
                        }
                        this.certificateFileOnServer = responseData.cert_file;
                        if (responseData.info_file) {
                            this.infoFileList.push(
                                {
                                    uid: '2',
                                    utf8Name: responseData.info_file,
                                    name: decodeURIComponent(responseData.info_file),
                                    status: 'done',
                                }
                            );
                        }
                        this.infoFileOnServer = responseData.info_file;

                        this.hasChanged = false;
                        if (this.enterpriseName) {
                            this.hasUpdated = true;
                            user.isFirstFill = false;
                        }
                    }
                })
                .catch((error) => {
                    console.log(cgiUrl, "失败：", error);
                });
            },

            // 上传企业信息
            updateEnterpriseInfo() {
                if (this.submitDisable) {
                    return
                }

                const updateUserInfoReq = new UpdateUserInfoRequestParams(user.uid);
                updateUserInfoReq.info_file = this.infoFilename;  //信息文件
                updateUserInfoReq.cert_file = this.certificateFilename;  //证书文件
                updateUserInfoReq.enterprise_name = this.enterpriseName;  //企业名
                updateUserInfoReq.contact = this.contact;  //联系电话
                updateUserInfoReq.bussiness_area = this.businessActivity;  //企业领域
                updateUserInfoReq.address = this.address;  //企业注册地址
                // updateUserInfoReq.founding_time = "2023/10/17";  //创办时间
                updateUserInfoReq.enterprise_role = this.enterpriseRoles;  //企业角色
                updateUserInfoReq.founder_info = this.founderInfo; //创始人信息
                updateUserInfoReq.event_hosting = this.eventHostingCases; //举办过的活动

                const cgiUrl = API_HOST + '/updateUserInfo';
                const body = JSON.stringify(updateUserInfoReq);
                fetch(cgiUrl, {
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    console.log("结果：", response);
                    if (!response.ok) {
                        console.log(cgiUrl, '失败，错误码：' + response.status);
                        return;
                    }
                    return response.json(); // 解析JSON响应;
                })
                .then((responseData) => {
                    console.log('数据:', responseData);
                    if (responseData.code == RESP_CODE.SUCCESS) {
                        this.enterpriseNameOnServer = this.enterpriseName;
                        this.contactOnServer = this.contact;
                        this.businessActivityOnServer = this.businessActivity;
                        this.addressOnServer = this.address;
                        this.rolesOnServer = JSON.parse(JSON.stringify(this.roles));
                        this.founderInfoOnServer = this.founderInfo;
                        this.eventHostingCasesOnServer = this.eventHostingCases;
                        this.certificateFileOnServer = this.certificateFilename;
                        this.infoFileOnServer = this.infoFilename;
                        this.hasChanged = false;
                        
                        if (!this.hasUpdated) {
                            this.setTopBarVisible(true, true, true);
                            this.$router.push("diary");
                        }
                        this.hasUpdated = true;
                        user.isFirstFill = false;
                    }
                })
                .catch((error) => {
                    console.log(cgiUrl, "失败：", error);
                });
            },

            // 上传文件
            customUploadFile(options) {
                const folderPath = getFolderPath(user.uid, STORAGE_NAME.FILE);
                uploadFile(folderPath, options.file, (e) => {
                    options.onProgress({ percent: Math.round(e)});
                })
                .then((response) => {
                    options.file.utf8Name = response;
                    options.onSuccess(response);
                })
                .catch((err) => {
                    console.log(err);
                    options.onError(err);
                });
            },
        },
        created() {
            this.getRoles();
            this.queryEnterpriseInfo();
        },
        mounted() {
            this.setTopBarVisible(true, true, true);
        }
    }
</script>

<style scoped>
    .fill-info-card-role {
        display: inline-block;
        padding: 5px 16px;
        gap: 4px;
        border-radius: 2px 0px 0px 2px;
        border: 1px solid rgba(255, 255, 255, 0.40);
        background: #262626;
        color: rgba(255, 255, 255, 0.60);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        margin-right: 14px;
        cursor: pointer;
    }

    .fill-info-card-role-choose {
        border: 1px solid #CF80EB;
        color: #CF80EB;
    }
    
    .fill-info-card-input-pre {
        color: rgba(255, 255, 255, 0.40);
        text-align: right;
        
        /* Body/regular */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
    }
    
    .fill-info-card-input {
        display: flex;
        width: 540px;
        padding: 6px 12px;
        align-items: center;
        border-radius: 4px;
        border: 1px solid transparent;
        background: #FFFFFF1A;
        color: white;
        font-size: 14px;
    }

    .fill-info-card-textarea {
        display: flex;
        width: 540px;
        padding: 6px 12px;
        align-items: center;
        border-radius: 2px;
        border: 1px solid transparent;
        background: #FFFFFF1A;
        color: white;
        resize: none;
        font-size: 14px;
        line-height: 1.5em;
    }

    .fill-info-card-input:focus, .fill-info-card-textarea:focus {
        outline: none;
        border: 1px solid rgb(207, 128, 235);
    }
    
    .fill-info-card-name {
        color: rgba(255, 255, 255, 0.85);
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        padding-top: 30px;
        padding-bottom: 12px
    }
    
    .fill-info-import-file {
        /* width: 110px; */
        height: 40px;
        display: inline-flex;
        padding: 11px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        background: linear-gradient(270deg, #8549FD 0%, #9E5BF7 32.29%, #CF80EB 100%);
        border: 0px;
        color: #FFF;
        font-size: 16px;
        margin-bottom: 12px;
        cursor: pointer;
    }
    
    .fill-info-my-info {
        display: inline-block;
        vertical-align: middle;
        height: 50px;
        margin-left: 72px;
        padding-right: 100px;
    }
    
    .fill-info-avatar {
        vertical-align: middle;
        display: inline-block;
        width: 100px;
        height: 100px;
        flex-shrink: 0;
        border-radius: 100px;
        background: lightgray 50% / cover no-repeat;
    }
    
    .fill-info-card {
        width: 100%;
        flex-shrink: 0;
        padding-bottom: 24px;
        margin-bottom: 32px;
        padding-left: 32px;
        padding-right: 32px;
        padding-top: 24px;
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.10);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.05) 100%);
    }
    
    .fill-info-title-hint {
        color: #FFF;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-bottom: 28px;
    }
    
    .fill-info-title {
        font-family: PingFang SC;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 392px;
        background: linear-gradient(270deg, #8549FD 0%, #9E5BF7 32.29%, #CF80EB 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-bottom: 10px;
    }
    
    .fill-info-container {
        position: relative;
        /* z-index: 1; */
        padding-left: 180px;
        padding-right: 180px;
        padding-top: 80px;
        width: 100%;
        background-color: black;
    }
    .required-item {
        color: rgb(248, 59, 99)
    }

    .upload-file, .analyse-file, .analysis-result {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        background: rgba(0,0,0,0.4);
        backdrop-filter: blur(10px);
        font-family: PingFang SC;
        word-wrap: break-word;
    }

    .upload-file-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40%;
        padding: 24px 32px 40px 32px;
        background: #222222;
        border-radius: 4px;
        border: 2px rgba(255, 255, 255, 0.10) solid
    }

    .analyse-file-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40%;
        background: #262626;
        border-radius: 4px;
        border: 2px rgba(255, 255, 255, 0.10) solid
    }

    .analysis-result-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40%;
        padding: 48px 32px 40px 32px;
        background: #222222;
        border-radius: 4px;
        border: 2px rgba(255, 255, 255, 0.10) solid
    }

    .close-button {
        display: inline-block;
        position: absolute;
        top: 20px;
        right: 24px;
        width: 14px;
        height: 14px;
        cursor: pointer;
    }

    .upload-title {
        margin-bottom: 24px; 
        color: #CF80EB; 
        font-size: 18px; 
        font-family: PingFang SC; 
        font-weight: 500;
    }

    .upload-list-container {
        max-height: 450px;
        overflow: auto;
    }

    .upload-list-container::-webkit-scrollbar {
        width: 0;
    }

    .upload-list-container ::v-deep .ant-upload-drag {
        padding: 16px 0px;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 2px;
        border: 0;
    }

    .upload-list-item {
        position: relative;
        height: 66px;
        padding: 8px;
        margin-top: 20px;
        border: 1px solid rgba(255, 255, 255, 0.25);
        border-radius: 2px;
        font-size: 14px;
        transition: background-color 0.3s;
        display: flex;
        align-items: center;
    }

    .upload-list-item-error {
        border: 1px solid #ff4d4f;
        border-radius: 2px;
    }

    .upload-list-item-unsuccessful {
        opacity: 0.4;
    }

    .upload-list-item-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 8px;
        line-height: 1.5714285714285714;
        flex: auto;
        transition: all 0.3s;
        margin-left: 8px;
        color: #CF80EB;
        font-size: 14px;
        font-family: PingFang SC;
    }

    .upload-list-item-name-error, .upload-list-item-hint-error {
        color: #ff4d4f;
    }

    .upload-list-item-progress-format {
        color: #CF80EB;
        font-size: 14px;
        font-family: PingFang SC;
    }

    .upload-list-item-actions {
        display: flex;
        padding: 3px 5px;
        margin-left: 8px;
        align-items: center;
        cursor: pointer;
    }

    .upload-list-item-progress {
        position: absolute;
        left: 0; 
        bottom: 0;
        height: 3px;  
        background: linear-gradient(270deg, #8549FD 0%, #9E5BF7 32.29%, #CF80EB 100%);
    }
/* 
    .upload-list-container ::v-deep .ant-upload-list.ant-upload-list-picture .ant-upload-list-item {
        margin-top: 20px;
        border: 1px solid rgba(255, 255, 255, 0.25);
        border-radius: 2px;
    }

    .upload-list-container ::v-deep .ant-upload-list.ant-upload-list-picture .ant-upload-list-item-error {
        border: 1px solid #ff4d4f;
        border-radius: 2px;
    }

    ::v-deep .ant-upload-list.ant-upload-list-picture .ant-upload-list-item-error span.ant-upload-list-item-actions.picture::before {
        content: "Upload Error";
        display: inline-block;
        margin-right: 8px;
    }

    .upload-list-container ::v-deep .ant-upload-list-item-name {
        margin-left: 8px;
        color: #CF80EB;
        font-size: 14px;
        font-family: PingFang SC;
    }

    .upload-list-container ::v-deep .ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .anticon {
        color: rgb(248, 59, 99)
    }

    ::v-deep .ant-upload-list.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name {
        margin-bottom: 0;
    }

    ::v-deep .ant-upload-list.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-thumbnail {
        opacity: 0.4;
    }

    ::v-deep .ant-upload-list.ant-upload-list-picture .ant-upload-list-item .ant-upload-list-item-progress {
        width: 100%;
        left: 0;
        bottom: -9px;
        padding: 0;
    }

    ::v-deep .ant-progress-text {
        position: relative;
        right: 45px;
        bottom: 30px;
        color: #CF80EB;
        font-size: 14px;
        font-family: PingFang SC;
    }

    ::v-deep .ant-progress.ant-progress-show-info .ant-progress-outer {
        padding-inline-end: 0;
    } */

    .upload-text {
        margin: 0; 
        color: rgba(255, 255, 255, 0.85); 
        font-size: 16px; 
        font-family: PingFang SC;
    }

    .upload-hint {
        margin: 0; 
        color: rgba(255, 255, 255, 0.45); 
        font-size: 14px; 
        font-family: PingFang SC;
    }

    .upload-import-container {
        display: flex; 
        justify-content: center; 
        margin-top: 48px; 
        margin-bottom: 8px;
    }

    .upload-import {
        width: 200px;
        height: 40px;
        display: inline-flex;
        padding: 11px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        background: linear-gradient(270deg, #8549FD 0%, #9E5BF7 32.29%, #CF80EB 100%);
        border: 0px;
        color: #FFF;
        font-size: 16px;
        cursor: pointer;
    }

    .submit-disable {
        opacity: 0.4;
        cursor: default;
    }

    .btn-disable {
        opacity: 0.4;
        cursor: default;
    }
</style>