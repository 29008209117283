<template>
    <div style="width: 100%;height: 100%;background: black;opacity: 40%">
        <a-upload-dragger class="a-upload-dragger-class"
                          v-model:fileList="fileList"
                          name="file"
                          :multiple="true"
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          @change="handleChange"
                          @drop="handleDrop"
        >
            <p class="ant-upload-drag-icon">
                <inbox-outlined></inbox-outlined>
            </p>
            <p class="ant-upload-text">Click or drag file to this area to upload</p>
            <p class="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files
            </p>
        </a-upload-dragger>
    </div>
</template>
<script>
    import {InboxOutlined} from '@ant-design/icons-vue';
    import {message} from 'ant-design-vue';
    import {defineComponent, ref} from 'vue';

    export default defineComponent({
        components: {
            InboxOutlined,
        },
        setup() {
            const handleChange = info => {
                const status = info.file.status;
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            };
            return {
                handleChange,
                fileList: ref([]),
                handleDrop: e => {
                    console.log(e);
                },
            };
        },
    });
</script>

<style scoped>
    .a-upload-dragger-class {
        background: white;
        width: 544px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>