import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const STORAGE_NAME = {
    AVATAR: 'avatar', //头像
    MUSIC: 'music', //音乐
    FILE: 'file', //文件
}

// folder为STORAGE_NAME中的一项
export function getFolderPath(uid, folder) {
    return uid + '/' + folder;
}

export function getFilePath(uid, folder, fileName) {
    return getFolderPath(uid, folder) + '/' + fileName;
}

export function uploadFile(folerPath, file, onProgress) {
    return new Promise(async (resolve, reject) => {
        const storage = getStorage();
        //对file.name改为utf-8编码，防止中文乱码
        const utf8Name = encodeURIComponent(file.name);
        const filePath = folerPath + '/' + utf8Name;

        // 设置文件类型元数据
        const metadata = {
            contentType: file.type,
        };

        const storageRef = ref(storage, filePath);
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);
    
        // 监听上传进度
        uploadTask.on(
            'state_changed',
            (snapshot) => {
            // 如果提供了进度回调函数，调用它并传递进度百分比
            if (onProgress) {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('上传进度：', progress);
                onProgress(progress);
            }
            },
            (error) => {
            // 处理上传错误
            console.log('上传失败：', error);
            reject(error);
            },
            async () => {
            // 上传成功
            resolve(utf8Name);
            }
        );
    });
  }

// 通过路径获取下载URL
export function getDownloadURLByPath(path) {
    return new Promise(async (resolve, reject) => {
        const storage = getStorage();
        const storageRef = ref(storage, path);
        const downloadURL = await getDownloadURL(storageRef);
        resolve(downloadURL);
    });
}