<template>
<div class="match-easter-egg">
    <div class="match-easter-egg-container">
        <div style="height: 160px; text-align: center;">
            <img src="../assets/match.svg" style="width: 100%; height: 100%;">
        </div>

        <div style="margin-top: 32px; text-align: center;">
            <span class="match-text">Partner Matched!</span>
        </div>

        <div style="margin-top: 25px; text-align: center;">
            <img :src="avatarURL" class="avatar" style="vertical-align: middle;">
            <span style="color: white; font-size: 24px; vertical-align: middle;">{{ latestMatch.matched_user_info.name }} accepted your invitation</span>
        </div>

        <div style="margin-top: 24px; padding: 15px 20px; border-radius: 4px; border: 2px solid rgba(255, 255, 255, 0.1); background: #181818;">
            <div style="margin-bottom: 12px; color: white; font-size: 16px;">{{ latestMatch.event_info.event_name }}</div>
            <div style="margin-bottom: 8px; display: flex; align-items: center; color: rgba(255, 255, 255, 0.6); font-size: 14px;">
                <img src="../assets/location.svg" style="width: 16px; height: 16px; margin-right: 8px;">
                <span>{{ latestMatch.event_info.event_location ? latestMatch.event_info.event_location[0] : "Unlimited" }}</span>
            </div>
            <div style="display: flex; align-items: center; color: rgba(255, 255, 255, 0.6); font-size: 14px;">
                <img src="../assets/access-time.svg" style="width: 16px; height: 16px; margin-right: 8px;">
                <span>{{ getTimeText() }}</span>
            </div>
        </div>

        <div style="margin-top: 40px; display: flex; justify-content: space-between;">
            <button class="dismiss-button"
            @click="$emit('closeMatchEasterEgg')"><span>Dismiss</span></button>

            <button class="check-button"
            @click="$emit('checkLatestMatch', startDate)">Check Event</button>
        </div>
    </div>
</div>
</template>

<script>
import dayjs from "dayjs";
import { STORAGE_NAME, getFilePath, getDownloadURLByPath} from "../functions/fileUpDownload.js";


export default {
    props: ["latestMatch"],
    data() {
        return {
            avatarURL: null,
        }
    },
    computed: {
        startDate() {
            return dayjs(this.latestMatch.event_info.event_start_date * 1000);
        }
    },
    methods: {
        getTimeText() {
            const item = this.latestMatch;
            let startDate = dayjs(item.event_info.event_start_date * 1000).format('D/MM/YYYY');
            let endDate = dayjs(item.event_info.event_end_date * 1000).format('D/MM/YYYY');
            let timeTxt = "";
            if (item.event_info.event_times) {
                let times = [];
                for(let j = 0; j < item.event_info.event_times.length; j++) {
                    let timeItem = item.event_info.event_times[j];
                    let startTime = new Date(timeItem[0] * 1000);
                    let startHour = startTime.getUTCHours();
                    let startMinutes = startTime.getUTCMinutes();
                    if (startMinutes < 10) {
                        startMinutes = "0" + startMinutes;
                    }
                    let endTime = new Date(timeItem[1] * 1000);
                    let endHour = endTime.getUTCHours();
                    if (endHour == 0) {  // 如果结束时间的UTCHour为0，将其转化为24
                        endHour = 24;
                    }
                    let endMinutes = endTime.getUTCMinutes();
                    if (endMinutes < 10) {
                        endMinutes = "0" + endMinutes;
                    }
                    times.push(startHour + ":" + startMinutes + " - " + endHour + ":" + endMinutes);
                }
                timeTxt = times.join(",");
            }
            else {
                timeTxt = "Unlimited";
            }
            if (startDate == endDate) {
                timeTxt += ", " + startDate;
            }
            else {
                timeTxt += ", " + startDate + " - " + endDate;
            }
            return timeTxt;
        }
    },
    created() {
        // 获取头像URL
        const path = getFilePath(this.latestMatch.matched_uid, STORAGE_NAME.AVATAR, this.latestMatch.matched_user_info.avatar);
        getDownloadURLByPath(path).then((url) => {
            this.avatarURL = url;
        });
    }
}
</script>

<style scoped>
.match-easter-egg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0,0,0,0.4);
    backdrop-filter: blur(10px);
    font-family: PingFang SC;
    word-wrap: break-word;
    font-weight: 400;
}

.match-easter-egg-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 420px;
    height: 524px;
}

.match-text {
    display:inline-block; 
    font-size: 40px; 
    background-image: linear-gradient(to right, #c486e4, #7b54f4); 
    color: transparent; 
    background-clip: text;
}

.avatar {
    margin-right: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
}

.dismiss-button {
    width: 200px;
    height: 41px;
    padding: 4px 12px;
    border: 1.5px solid transparent;
    border-radius: 4px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #0b0b0b, #0b0b0b), linear-gradient(to right, #c486e4, #7b54f4);
    cursor: pointer;
}

.dismiss-button span {
    background-image: linear-gradient(to right, #c486e4, #7b54f4);
    color: transparent;
    background-clip: text;
    color: #8549FD;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 500;
}

.check-button {
    width: 200px;
    height: 41px;
    padding: 11px 16px;
    border: 0px;
    border-radius: 4px;
    background-image: linear-gradient(to right, #c486e4, #7b54f4);
    color: white;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 500;
    cursor: pointer;
}
</style>