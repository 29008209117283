import { BaseRequestParams, BaseResponseParams } from "./commonUtil";

/*
    请求
    * @param {string} uid - 用户uid
    * @param {string} file - 文件路径
    * @param {int} recognizeType - 识别类型
    * 
*/
export const FILE_RECOGNIZE_TYPE = {
    COMPANY_CERT: 1, //主办方认证
    COMPANY_INFO: 2, //主办方信息
    MUSICIAN_INFO: 3, //音乐人信息
}

export class FileRecognizeRequestParams extends BaseRequestParams {
    constructor(uid, file, recognizeType) {
        super(uid);
        this.file = file;
        this.recognizeType = recognizeType;
    }
}

/*
    响应
    * @param {int} code - 响应码, 
    * @param {string} message - 响应消息
*/
export class FileRecognizeResponseParams extends BaseResponseParams {
    constructor(code) {
        super(code);

        //COMPANY_CERT
        this.enterpriseName = null; //企业名
        this.contact = null; //联系电话
        this.bussinessArea = null; //企业领域
        this.address = null; //企业注册地址
        this.foundingTime = null; //创办时间

        //COMPANY_INFO
        this.enterpriseRole = null; //企业角色
        this.founderInfo = null; //创始人信息
        this.eventHosting = null; //举办过的活动

        //MUSICIAN_INFO
        this.occupationTime = null; //从业时长
        this.musicBackground = null; //音乐背景
        this.performanceExperience = null; //演出经历
    }
}