<template>
    <div style="width: 100%; height: 100%; position: absolute; left: 0; top: 0; font-family: PingFang SC; font-weight: 400; word-wrap: break-word">

        <div class="login-background-div">
            <img src="../assets/login-bg.png" style="width: 100%; height: 100%;"/>
        </div>

        <div class="login-container">
            <img style="position: absolute; top: 48px; left: 48px; width: 100px;" src="../assets/logo.svg" />

            <div class="login-left-container">
                <div class="login-left-welcome">Welcome to AI Agent:</div>
                <div class="login-left-welcome">Linking Tunes to Opportunities</div>

                <div style="margin-top: 24px; color: #FFF; font-size: 16px; line-height: 1.5em;">
                    An AI-powered music agent<br/>Let every melody discovers its ideal stage
                </div>

                <div class="google-login-button"
                @click="googleSignIn">
                    <img src="../assets/google-login.svg" style="width: 20px; height: 20px; margin-right: 8px; vertical-align: middle;">
                    <span style="vertical-align: middle;">Log in with Google</span>
                </div>

                <!-- <div style="margin-top: 25px; display: flex; justify-content: center; color: white; font-size: 14px;">
                    <span>or with</span>
                    <img src="../assets/wechat.svg" style="width: 17px; height: 15px; margin-left: 6px; margin-right: 2px; cursor: pointer;">
                    <span>Wechat</span>
                </div> -->
            </div>

            <!-- <div class="login-right-container">
                <div style="display: flex; justify-content: center;">
                    <div class="qr-code-container">
                        <img src="../assets/qr-code.svg" style="width: 156px; height: 156px;">
                        <div class="login-mask" v-if="isLogin">
                            <img src="../assets/login-circle.svg" style="width: 54px; height: 54px; margin-top: 46px;">
                            <div style="margin-top: 12px; color: black; font-size: 16px;">Confirm on phone</div>
                        </div>
                    </div>
                </div>

                <div style="margin-top: 16px; display: flex; justify-content: center; align-items: center; color: white; font-size: 14px;">
                    <span>Log in or Sign in with</span>
                    <img src="../assets/wechat.svg" style="width: 17px; height: 15px; margin-left: 6px; margin-right: 2px;">
                    <span>Wechat</span>
                </div>

                <div class="google-login-button">
                    <img src="../assets/google-login.svg" style="width: 20px; height: 20px; margin-right: 8px;">
                    with Google
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
    import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
    import { LoginRequestParams } from "../functions/loginParams.js";
    import { LOGIN_TYPE, USER_ROLE, RESP_CODE } from "../functions/commonUtil.js";
    import { user, API_HOST } from "../js/global.js";
    import { QueryUserInfoRequestParams, QUERY_USER_INFO_TYPE } from "@/functions/queryUserInfoParams.js";

    import {ref} from "vue"

    export default {
        setup() {
            const toastMessage = ref('');
            const isLogin = ref(false);  // 控制 Confirm on phone 的渲染

            return {
                toastMessage,
                isLogin,
                // googleSignIn: () => googleSignIn(toastMessage)
            };
        },

        methods: {
            setTopBarVisible(visible) {
                this.$emit('setTopBarVisible', visible);
            },

            googleSignIn() {
                console.log("谷歌登录");
                const auth = getAuth();
                var provider = new GoogleAuthProvider();
                signInWithPopup(auth, provider)
                .then((result) => {
                    console.log("登录成功：", result);
                    // 转换为自定义用户结构体
                    const loginReq = new LoginRequestParams(LOGIN_TYPE.GOOGLE, 
                        result.user.uid, 
                        result.user.displayName, 
                        result.user.email, 
                        result.user.photoURL);
                    const body = JSON.stringify(loginReq); // 定义body变量
                    // 调用登录接口
                    // fetch('http://127.0.0.1:5001/aimusicagent/us-central1/api/loginIn', {
                    fetch(API_HOST + '/loginIn', {
                        method: 'POST',
                        body: body,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((response) => {
                        console.log("结果：", response);
                        if (!response.ok) {
                            console.log('失败，错误码：' + response.status);
                            return;
                        }
                        return response.json();
                    })
                    .then((responseData) => {
                        console.log('数据:', responseData);

                        document.cookie = "uid=" + responseData.uid + ";";
                        user.uid = responseData.uid;

                        // 查询用户角色
                        const queryUserInfoReq = new QueryUserInfoRequestParams(user.uid, QUERY_USER_INFO_TYPE.ALL_INFO);
                        const cgiUrl = API_HOST + '/queryUserInfo';
                        const body2 = JSON.stringify(queryUserInfoReq);
                        fetch(cgiUrl, {
                            method: 'POST',
                            body: body2,
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                        .then((response) => {
                            if (!response.ok) {
                                return;
                            }
                            return response.json();
                        })
                        .then((responseData) => {
                            if (responseData.code == RESP_CODE.NEED_IDENTITY_SELECT) {}  // 需要选择身份
                            if (responseData.code == RESP_CODE.NEED_FILL_INFO) {}  // 需要补充资料
                            if (responseData.code == RESP_CODE.NEED_ADD_EMAIL) {}  // 需要填充邮箱
                            if (responseData.code == RESP_CODE.SUCCESS) {
                                user.userRole = responseData.user_role;
                                user.name = responseData.name;
                                if (user.userRole == USER_ROLE.COMPANY) {
                                    if (responseData.enterprise_name) {
                                        user.isFirstFill = false;
                                    }
                                }
                                else if (user.userRole == USER_ROLE.MUSICIAN) {
                                    if (responseData.occupation_time) {
                                        user.isFirstFill = false;
                                    }
                                }
                            }
                            console.log("user", user);
                            this.routeNextPage();
                        })
                    })
                    .catch((error) => {
                        console.log("登录失败：", error);
                    });
                })
                .catch((error) => {
                console.log("登录失败：", error);
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                // const email = error.customData.email;
                // The AuthCredential type that was used.
                // const credential = GoogleAuthProvider.credentialFromError(error);
                // 弹出错误提示
                });
            },

            routeNextPage() {
                if (user.uid && user.userRole == USER_ROLE.COMPANY) {
                    if (user.isFirstFill) {
                        this.$router.push("fill-company-info");
                    }
                    else {
                        this.$router.push("diary");
                    }
                }

                else if (user.uid && user.userRole == USER_ROLE.MUSICIAN) {
                    if (user.isFirstFill) {
                        this.$router.push("fill-personal-info");
                    }
                    else {
                        this.$router.push("diary");
                    }
                }

                else if (user.uid) {
                    this.$router.push("identity-select");
                }
            }
        },

        mounted() {
            this.setTopBarVisible(false);

            if (user.uid) {
                this.routeNextPage();
            }
        }
    };
</script>

<style scoped>

    .login-background-div {
        display: flex;
        position: absolute;
        width: 100%; 
        height: 100%;  
        z-index: 0;
        padding-left: 300px;
    }
    
    .login-background-div::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
        mix-blend-mode: darken;
        backdrop-filter: blur(4px);
    }

    .login-container {
        position: relative;
        width: 50%;
        height: 100%;
        background: rgba(0, 0, 0, 0.40);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px; 
        backdrop-filter: blur(5px);
        z-index: 2;
    }

    .login-left-container {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        text-align: center;
    }

    .login-right-container {
        width: 327px;
        height: 420px;
        position: absolute;
        top: 50%;
        right: 180px;
        transform: translateY(-50%);
        padding: 48px 55px 64px 55px;
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.10);
        background: rgba(255, 255, 255, 0.10);
        backdrop-filter: blur(15px);
    }

    .login-left-welcome {
        font-family: PingFang SC;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        background: linear-gradient(270deg, #8549FD 0%, #9E5BF7 32%, #CF80EB 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 1.5em;
    }

    .qr-code-container {
        position: relative;
        display: inline-block;
        padding: 12px;
        border-radius: 4px;
        background-color: white;
    }

    .login-mask {
        position: absolute;
        width: 100%; 
        height: 100%; 
        top: 0; 
        left: 0;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.95);
        text-align: center;
    }

    .google-login-button {
        display: inline-block;
        padding: 10px 28px;
        margin-top: 72px;
        color: white; 
        font-size: 16px; 
        font-family: Roboto;
        border-radius: 4px;
        background: linear-gradient(270deg, #8549FD 0%, #9E5BF7 32%, #CF80EB 100%);
        cursor: pointer;
    }
</style>