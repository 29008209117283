<template>
    <div class="container" style="height: 100%"
    v-if="isLoaded">
        <TopBar :topBarVisible="topBarVisible" ref="topBar"></TopBar>
        <router-view @setTopBarVisible="setTopBarVisible"></router-view>
        <!--<router-link to="/op">点我跳转</router-link>-->
    </div>
</template>

<script>
    import {globalFunction, globalVariable, user, API_HOST} from './js/global.js'
    import { QueryUserInfoRequestParams, QUERY_USER_INFO_TYPE } from "@/functions/queryUserInfoParams.js";
    import { RESP_CODE, USER_ROLE } from "./functions/commonUtil.js";
    import TopBar from "./components/TopBar";
    

    globalFunction()
    console.log(globalVariable)

    export default {
        name: 'App',
        components: {TopBar},
        data() {
            return {
                topBarVisible: true,
                isLoaded: false,  // 只有获取完用户角色后才渲染APP
            }
        },
        methods: {
            setTopBarVisible(visible, refreshUserInfo=false, showExit=false) {
                this.topBarVisible = visible;
                if (refreshUserInfo) {
                    this.$refs.topBar.queryUserInfo();
                }
                this.$refs.topBar.showExit(showExit);
            },

            getUserInfo(user) {
                const queryUserInfoReq = new QueryUserInfoRequestParams(user.uid, QUERY_USER_INFO_TYPE.ALL_INFO);
                const cgiUrl = API_HOST + '/queryUserInfo';
                const body = JSON.stringify(queryUserInfoReq);

                fetch(cgiUrl, {
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    if (!response.ok) {
                        return;
                    }
                    return response.json();
                })
                .then((responseData) => {
                    console.log(responseData);
                    if (responseData.code == RESP_CODE.SUCCESS) {
                        user.userRole = responseData.user_role;
                        user.name = responseData.name;
                        if (user.userRole == USER_ROLE.COMPANY) {
                            if (responseData.enterprise_name) {
                                user.isFirstFill = false;
                            }
                        }
                        else if (user.userRole == USER_ROLE.MUSICIAN) {
                            if (responseData.occupation_time) {
                                user.isFirstFill = false;
                            }
                        }
                    }
                    this.isLoaded = true;

                    // 如果当前路由为主界面，跳转到登陆界面
                    // if (this.$route.path == "/" ) {
                    //     this.$router.push("login");
                    // }
                })
            }
        },
        created() {
            this.getUserInfo(user);
        }
    }
    
</script>

<style>
    #app {
        color: #2c3e50;
    }
    
    .container {
        position: relative;
        min-width: 1440px;
        background: black;
        align-items: stretch !important;
    }
</style>
