<template>
    <div class="date-details-container">test</div>
</template>

<script>
    export default {
        name: "DateDetails"
    }
</script>

<style scoped>
    .date-details-container {
        position: fixed;
        z-index: 1000;
        width: 200px;
        height: 300px;
        background: aqua;
    }
</style>