<template>
    <div class="calendar-container" @click="handleClick">
        <div class="main-calendar-container">
            <MainCalendar ref="calendar"
            @changeEventsList="changeEventsListHandler"
            @requestEvents="requestEventsHandler"></MainCalendar>
            <DateDetails v-if="floatingVisible" :style="{ left: x + 'px', top: y + 'px' }" type="primary"></DateDetails>
        </div>
        <div class="events-container">
            <div class="events-date">Events on {{ currentDate.format('D/MM/YYYY') }}</div>

            <div v-show="isEventsListLoading" class="events-list-empty">
                Loading...
            </div>

            <div v-show="!isEventsListLoading && isEventsListEmpty" class="events-list-empty">
                No planned events
            </div>

            <div v-show="!isEventsListLoading && !isEventsListEmpty" class="events-list-container">
                <div class="events-list">
                    <div class="events-list-title">Confirmed Events</div>
                    <div class="event-confirmed" v-for="(item, index) in confirmedEvents" :key="index">
                        <div class="event-title">{{ item.title }}</div>
                        <div class="event-info">
                            <div class="event-avatar"><img :src="item.imgSrc" /></div>
                            <span style="margin-left: 8px;">{{ item.name }}</span></div>
                        <div class="event-info">
                            <div class="event-avatar"><img src="../assets/location.svg" style="width: 20px; height: 20px;"/></div>
                            <span style="margin-left: 8px;">{{ item.location }}</span></div>
                        <div class="event-info" style="margin-bottom: 0px;">
                            <div class="event-avatar"><img src="../assets/access-time.svg" style="width: 20px; height: 20px;"/></div>
                            <span style="margin-left: 8px;">{{ item.time }}</span></div>
                    </div>
                </div>
                <div class="events-list">
                    <div class="events-list-title">Planned Events</div>
                    <div class="event" v-for="(item, index) in plannedEvents" :key="index"
                    @click="clickEventHandler(item, index)">
                        <div class="event-title">{{ item.title }}</div>
                        <div class="event-info">
                            <div class="event-avatar"><img src="../assets/location.svg" style="width: 20px; height: 20px;"/></div>
                            <span style="margin-left: 8px;">{{ item.location }}</span>
                            <div class="matching-container">
                                <div style="display: inline-block; position: relative;">
                                    <span :class="[item.matchingRes.length == 0 ? 'no-matching' : ''] ">{{ getMatchingText(item, isMusician) }}</span>
                                    <div class="musicians-red-dot" v-if="item.newMatchingCount > 0"><span>{{ item.newMatchingCount }}</span></div>
                                </div>
                                <img src="../assets/ooui_next-ltr.png"></div>
                            </div>
                        <div class="event-info" style="margin-bottom: 0px;">
                            <div class="event-avatar"><img src="../assets/access-time.svg" style="width: 20px; height: 20px;"/></div>
                            <span style="margin-left: 8px;">{{ item.time }}</span></div>
                    </div>
                </div>
            </div>

            <button class="add-button"
            @click="showAiDialogue = true">
                <img src="../assets/add-event-logo.svg" style="width: 34px; height: 34px; margin-right: 12px;">
                New Event
            </button>
        </div>
        
    </div>
    <!-- 日程栏 -->
    <EventsBar v-show="showEventsBar"
    :eventId="eventId"
    :matchList="matchList"
    @hideEventsBar="showEventsBar = false;"
    @showEventDetails="showEventDetailsHandler"
    @changeMatchListStatus="changeMatchListStatusHandler"
    @ignoreMatch="ignoreMatchHandler"
    @acceptMatch="acceptMatchHandler"></EventsBar>
    <!-- 日程详情 -->
    <EventDetails v-if="showEventDetails"
    :eventDetails="eventDetails"
    :eventId="eventId"
    :currentDate="currentDate"
    @changeMatchStatus="changeMatchStatusHandler"
    @closeEventDetails="showEventDetails = false"></EventDetails>
    <!-- 新建日程 -->
    <NewEvent v-show="showNewEvent" 
    @closeNewEvent="showNewEvent = false"
    @postEvent="postEventHandler"></NewEvent>
    <!-- AI对话 -->
    <AiDialogue v-if="showAiDialogue"
    @closeAiDialogue="showAiDialogue = false;"
    @editEvent="showAiDialogue = false; showNewEvent = true"></AiDialogue>
    <!-- 日程发布 -->
    <EventPost v-show="showEventPost"
    :postingEvent="postingEvent"
    :postProgress="postProgress"
    :matchList="matchList"
    @closeEventPost="showEventPost = false;"
    @showEventsBar="showEventsBarHandler"></EventPost>
    <!-- 匹配成功彩蛋 -->
    <MatchEasterEgg v-if="showEasterEgg"
    id="match-easter-egg"
    :latestMatch="latestMatch"
    @closeMatchEasterEgg="showEasterEgg = false;"
    @checkLatestMatch="checkLatestMatchHandler"
    ></MatchEasterEgg>
</template>

<script>
    import MainCalendar from "../components/MainCalendar";
    import DateDetails from "../components/DateDetails";
    import EventsBar from '../components/EventsBar'
    import EventDetails from "../components/EventDetails"
    import NewEvent from "../components/NewEvent"
    import AiDialogue from "../components/AiDialogue"
    import EventPost from "../components/EventPost"
    import MatchEasterEgg from "../components/MatchEasterEgg"
    import {defineComponent, ref} from 'vue'
    import dayjs from "dayjs";
    import { EVENT_STATUS, USER_ROLE, BaseRequestParams } from "../functions/commonUtil.js";
    import { user, API_HOST } from "../js/global.js";
    import { QueryMatchsRequestParams } from "../functions/queryMatchsParams.js";
    import { STORAGE_NAME, getFilePath, getDownloadURLByPath} from "../functions/fileUpDownload.js";

    export default defineComponent({
        name: "MyCalendarPage",
        components: {
            MainCalendar,
            DateDetails,
            EventsBar,
            EventDetails,
            NewEvent,
            AiDialogue,
            EventPost,
            MatchEasterEgg
        },
        data() {
            return {
                dateEventsMap: null,
                showEventsBar: false,  // 切换日程栏的显示与隐藏
                showEventDetails: false,  // 日程详情的显示与隐藏
                showNewEvent: false,  // 新建日程的显示与隐藏
                showAiDialogue: false,  // AI对话的显示与隐藏
                showEventPost: false,  // 发布成功的显示与隐藏
                postingEvent: false,  // 日程发布中的显示与隐藏
                showEasterEgg: false,  // 匹配彩蛋的显示与隐藏
                // confirmedEvents: [
                //     {
                //         title: "Strawberry Music Festival",
                //         name: "Walner Music",
                //         location: "New York City",
                //         time: "13:00 - 14:00, 9/01/2023",
                //         imgSrc: "../assets/avatar.png"
                //     }
                // ],
                // plannedEvents: [
                //     {
                //         title: "Strawberry Music Festival",
                //         name: "Walner Music",
                //         location: "New York City",
                //         time: "13:00 - 14:00, 9/01/2023",
                //         imgSrc: "../assets/avatar.png",
                //         matchingRes: ["a", "b", "c"],
                //         hasNewMatching: true,
                //     },
                //     {
                //         title: "Strawberry Music Festival",
                //         name: "Walner Music",
                //         location: "New York City",
                //         time: "13:00 - 14:00, 9/01/2023",
                //         imgSrc: "../assets/avatar.png",
                //         matchingRes: ["a", "b", "c", "d"],
                //         hasNewMatching: false,
                //     },
                // ],
                lastChangeListTimestamp: null,  // 保存最后一次请求匹配列表的时间戳
                currentDate: dayjs(),  // 选定的日期
                confirmedEvents: [],
                plannedEvents: [],
                eventId: null,  // 当前处理的eventId，传入EventsBar、EventDetails组件
                matchList: [],  // 当前处理的matchList，传入EventsBar组件
                eventDetails: null,  // 传入EventDetails组件
                latestMatch: null,  // 传入MatchEasterEgg组件
                // currentEventIndex: null,  // 选定的日程在plannedEvents中的索引

                postProgress: 0,
            }
        },
        computed: {
            isMusician() {  // true-音乐人界面，false-主办方界面
                if (user.userRole == USER_ROLE.MUSICIAN) {
                    return true;
                } 
                else {
                    return false;
                }
            },

            isEventsListLoading() {
                return !this.confirmedEvents || !this.plannedEvents;
            },
            
            isEventsListEmpty() {
                return this.confirmedEvents.length == 0 && this.plannedEvents.length == 0;
            },
        },
        methods: {
            setTopBarVisible(visible, refreshUserInfo=false, showExit=false) {
                this.$emit('setTopBarVisible', visible, refreshUserInfo, showExit);
            },

            getMatchingText(item, isMusician) {
                var size = item.matchingRes.length;
                if(size == 0) {
                    return "Matching";
                }
                else {
                    if (size == 1) {
                        return size + (isMusician ? " Host" : " Musician");
                    }
                    else {
                        return size + (isMusician ? " Hosts" : " Musicians");
                    }
                }
            },

            requestEventsHandler(dateEventsMap) {
                this.dateEventsMap = dateEventsMap;
            },

            timestampToDate(timestamp) {
                return timestamp.startOf('day').format('YYYY-MM-DD');
            },

            // currentEvents(current) {
            //     return this.dateEventsMap[this.timestampToDate(current)];
            // },

            async postEventHandler(event_id, event_start_date) {
                const queryMatchsReq = new QueryMatchsRequestParams(user.uid, event_id);
                const cgiUrl = API_HOST + '/queryMatchs';
                const body = JSON.stringify(queryMatchsReq);
                await fetch(cgiUrl, {
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    console.log("结果：", response);
                    if (!response.ok) {
                        console.log(cgiUrl, '失败，错误码：' + response.status);
                        return;
                    }
                    return response.json(); // 解析JSON响应;
                })
                .then((responseData) => {
                    console.log('数据:', responseData);
                    if (responseData) {
                        this.matchList = responseData.match_list;
                        this.eventId = event_id;
                        this.currentDate = event_start_date;

                        this.$refs.calendar.updateEvents(this.currentDate);

                        this.showNewEvent = false; 
                        this.postProgress = 0;
                        this.postingEvent = true;
                        this.showEventPost = true;

                        // 强制loading 3s
                        let progress = 0;
                        const increment = 100 / (3000 / 10);
                        const intervalId = setInterval(() => {
                            if (progress < 100) {
                                progress += increment;
                                this.postProgress = Math.round(progress);
                            }
                            else {
                                this.analyseProgress = 100;
                                clearInterval(intervalId);
                                this.postingEvent = false;
                            }
                        }, 10);
                    }
                })
                .catch((error) => {
                    console.log(cgiUrl, "失败：", error);
                });
            },

            showEventsBarHandler() {
                this.showEventPost = false; 
                this.showEventsBar = true;
            },

            async changeEventsListHandler(current, currentDateKey) {
                this.lastChangeListTimestamp = Date.now();
                const lastChangeListTimestamp = this.lastChangeListTimestamp;

                this.currentDate = current;
                const eventsList = this.dateEventsMap[currentDateKey];
                const confirmed = [];
                const planned = [];
                this.confirmedEvents = null;
                this.plannedEvents = null;
                if (eventsList) {
                    console.log(eventsList);
                    for(let i = 0; i < eventsList.length; i++) {
                        let eventItem = eventsList[i];

                        // 将日程(信息、匹配情况)添加到confirmedEvents、plannedEvents中
                        let temp = {};
                        temp.eventId = eventItem.event_id;  // 记录，将用于UpdateMatchStatus接口
                        temp.title = eventItem.event_info.event_name;
                        temp.location = eventItem.event_info.event_location ? eventItem.event_info.event_location[0] : "Unlimited";
                        let startDate = dayjs(eventItem.event_info.event_start_date * 1000).format('D/MM/YYYY');
                        let endDate = dayjs(eventItem.event_info.event_end_date * 1000).format('D/MM/YYYY');
                        if (eventItem.event_info.event_times) {
                            let times = [];
                            for(let j = 0; j < eventItem.event_info.event_times.length; j++) {
                                let timeItem = eventItem.event_info.event_times[j];
                                let startTime = new Date(timeItem[0] * 1000);
                                let startHour = startTime.getUTCHours();
                                let startMinutes = startTime.getUTCMinutes();
                                if (startMinutes < 10) {
                                    startMinutes = "0" + startMinutes;
                                }
                                let endTime = new Date(timeItem[1] * 1000);
                                let endHour = endTime.getUTCHours();
                                if (endHour == 0) {  // 如果结束时间的UTCHour为0，将其转化为24
                                    endHour = 24;
                                }
                                let endMinutes = endTime.getUTCMinutes();
                                if (endMinutes < 10) {
                                    endMinutes = "0" + endMinutes;
                                }
                                times.push(startHour + ":" + startMinutes + " - " + endHour + ":" + endMinutes);
                            }
                            temp.time = times.join(",");
                        }
                        else {
                            temp.time = "Unlimited";
                        }
                        if (startDate == endDate) {
                            temp.time += ", " + startDate;
                        }
                        else {
                            temp.time += ", " + startDate + "-" + endDate;
                        }

                        // 获取匹配成功列表
                        if (eventItem.event_status == EVENT_STATUS.MATCHED) {
                            const matchedUid = eventItem.matched_uid;
                            const matchedAvatar = eventItem.matched_user_info.avatar;
                            const path = getFilePath(matchedUid, STORAGE_NAME.AVATAR, matchedAvatar);

                            temp.name = eventItem.matched_user_info.name;
                            await getDownloadURLByPath(path).then((url) => {
                                temp.imgSrc = url;
                            });
                            confirmed.push(temp);
                        }
                        // 获取匹配中列表
                        else {
                            const queryMatchsReq = new QueryMatchsRequestParams(user.uid, eventItem.event_id);
                            const cgiUrl = API_HOST + '/queryMatchs';
                            const body = JSON.stringify(queryMatchsReq);
                            await fetch(cgiUrl, {
                                method: 'POST',
                                body: body,
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            }).then((response) => {
                                console.log("结果：", response);
                                if (!response.ok) {
                                    console.log(cgiUrl, '失败，错误码：' + response.status);
                                    return;
                                }
                                return response.json(); // 解析JSON响应;
                            })
                            .then((responseData) => {
                                console.log('数据:', responseData);
                                
                                const matchList = responseData.match_list;
                                console.log("匹配列表：", matchList);
                                
                                temp.matchingRes = matchList;
                                temp.newMatchingCount = 0;
                                for (let j = 0; j < temp.matchingRes.length; j++) {
                                    if (temp.matchingRes[j].red_dot) {
                                        temp.newMatchingCount += 1;
                                    }
                                }
                                planned.push(temp);
                            })
                            .catch((error) => {
                                console.log(cgiUrl, "失败：", error);
                            });
                        }
                    }
                }
                
                if (lastChangeListTimestamp == this.lastChangeListTimestamp) {
                    this.confirmedEvents = confirmed;
                    this.plannedEvents = planned;

                    console.log(eventsList);
                    console.log(this.confirmedEvents);
                    console.log(this.plannedEvents);
                }
            },

            clickEventHandler(eventItem, eventIndex) {
                // 点击日程获取匹配列表处理
                this.matchList = eventItem.matchingRes;
                this.eventId = eventItem.eventId;
                this.showEventsBar = true;

                // this.currentEventIndex = eventIndex;

                console.log(this.matchList);
            },

            ignoreMatchHandler(index) {
                // 忽略或拒绝某个匹配项
                this.matchList.splice(index, 1);
                // this.$refs.calendar.updateEvents(this.currentDate);
            },

            acceptMatchHandler(index) {
                // 接受某个匹配项
                // this.matchList.splice(index, 1);
                // this.$refs.calendar.updateEvents(this.currentDate);
            },

            changeMatchListStatusHandler(index, newMatchStatus) {  // 从EventsBar中改变匹配状态
                this.matchList[index].match_status = newMatchStatus;
                this.$refs.calendar.updateEvents(this.currentDate);
            },

            showEventDetailsHandler(eventItem) {
                console.log("eventItem", eventItem);
                // 点击日程获取日程详情处理
                this.eventDetails = eventItem;
                this.showEventDetails = true;
            },

            changeMatchStatusHandler(newMatchStatus) {
                this.eventDetails.match_status = newMatchStatus;
                this.$refs.calendar.updateEvents(this.currentDate);
            },

            // 最近一次匹配
            queryLatestMatch() {
                const queryLatestMatchReq = new BaseRequestParams(user.uid);
                const cgiUrl = API_HOST + '/queryLatestMatch';
                const body = JSON.stringify(queryLatestMatchReq); // 定义body变量
                fetch(cgiUrl, {
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    console.log("结果：", response);
                    if (!response.ok) {
                        console.log(cgiUrl, '失败，错误码：' + response.status);
                        return;
                    }
                    return response.json(); // 解析JSON响应;
                })
                .then((responseData) => {
                    console.log('最新一次匹配，数据:', responseData);
                    if (responseData.matched_user_info) {
                        this.latestMatch = responseData;
                        this.showEasterEgg = true;
                    }
                })
                .catch((error) => {
                    console.log("失败：", error);
                });
            },

            // 点击彩蛋Check Event按钮的事件处理
            checkLatestMatchHandler(eventDate) {
                this.showEasterEgg = false;
                this.$refs.calendar.updateSelectedDate(eventDate);
            }
        },
        created() {
            this.queryLatestMatch();
        },
        mounted() {
            this.setTopBarVisible(true, true, false);
        },
        setup() {
            const x = ref(0);
            const y = ref(0);
            const floatingVisible = ref(false);
            const disableClick = ref(false)

            function handleDateSelect(date) {
                // 在这里处理点击事件
                console.log('选中的日期:', date.format('YYYY-MM-DD'));
                // floatingVisible.value = true
                // disableClick.value = true
            }

            function handleClick(event) {
                if (disableClick.value == false) {
                    floatingVisible.value = false
                }
                x.value = event.clientX
                y.value = event.clientY
                disableClick.value = false

                console.log("点击啦啦啦      " + x.value + "   " + y.value)
            }

            return {
                x, y, floatingVisible, handleClick, handleDateSelect
            }
        },
    })

</script>

<style scoped>
    .calendar-container {
        background: linear-gradient(180deg, rgba(28, 28, 28, 100) 0%, rgba(28, 28, 28, 70) 50%, rgba(28, 28, 28, 0) 100%);
        height: 100%;
        margin: 74px 80px 38px 80px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        z-index: 5;
        font-family: PingFang SC;
    }

    .main-calendar-container {
        width: 66%;
        height: 100%;
        padding: 0px 24px 24px 32px;
        float: left;
    }

    .events-container {
        position: relative;
        width: 34%;
        height: 100%;
        float: right;
        padding: 24px 32px 84px 0px;
        display: flex;
        flex-direction: column;
    }

    .events-date {
        margin-bottom: 24px;
        color: white;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        word-wrap: break-word;
    }
    .events-list-title {
        margin-bottom: 12px;
        color: rgba(255, 255, 255, 0.60);
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        word-wrap: break-word
    }
    .event {
        padding: 16px 20px 20px 20px;
        margin-bottom: 12px;
        background-color: black;
        border: 1px solid transparent;
        cursor: pointer;
    }

    .event-confirmed {
        padding: 16px 20px 20px 20px;
        margin-bottom: 12px;
        background-color: black;
        border: 1px solid transparent;
    }

    .event:hover {
        border-color: rgba(207, 128, 235, 1);
    }

    .event-title {
        margin-bottom: 12px;
        color: white;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        word-wrap: break-word
    }
    .event-info {
        position: relative;
        color: rgba(255, 255, 255, 0.60);
        font-size: 14px;
        font-weight: 400;
        word-wrap: break-word;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-bottom: 8px;
    }

    .event-avatar {
        width: 20px;
        height: 20px;
        display: inline-block;
    }
    .event-avatar img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .matching-container {
        display: inline-block;
        position: absolute;
        right: 0;
    }

    .matching-container span {
        display: inline-block;
        color: #CF80EB;
        font-size: 16px;
        margin-right: 4px;
    }

    .no-matching {
        opacity: 0.6;
    }

    .musicians-red-dot {
        position: absolute;
        right: 0;
        top: -12px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        font-size: 10px;
        color: white;
        background-color: rgba(248, 59, 99);
        text-align: center;
    }

    .musicians-red-dot span {
        color: white;
        margin-right: 0;
        font-size: 12px;
    }

    .matching-container img {
        margin-right: 20px;
    }
    
    .add-button {
        display: inline-flex;
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        padding: 10px 28px;
        border-radius: 100px;
        background: linear-gradient(to right, #be82e6, #855af1);
        justify-content: center;
        align-items: center;
        z-index: 5;
        cursor: pointer;
        border: 0;
        outline: none;
        font-size: 16px;
        color: white;
        font-size: 16px;
        font-family: Roboto;
        font-weight: 500;
    }

    .events-list-container {
        height: 0;
        flex: 1;
        overflow: auto;
    }

    .events-list-container::-webkit-scrollbar {
        width: 0;
    }

    .events-list-empty {
        height: 0;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(255, 255, 255, 0.40);
        font-size: 21px;
    }
</style>