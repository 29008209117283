import {createApp} from 'vue'
import App from './App.vue'
import router from './js/router.js'
import firebaseApp from './js/firebase';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

// 将Firebase实例添加到Vue中
const app = createApp(App);
app.config.globalProperties.$firebase = firebaseApp;

app.use(router).use(Antd).mount('#app')
