import { BaseResponseParams} from "./commonUtil";

/*
    请求
    * @param {int} type - 登录类型, LOGIN_TYPE
    * @param {string} uid - 用户uid
    * @param {string} name - 用户名
    * @param {string} email - 用户邮箱
    * @param {string} photoURL - 用户头像
*/

export class LoginRequestParams {
    constructor(type, wechat_uid, name, email, photoURL) {
        this.type = type;
        this.wechat_uid = wechat_uid;
        this.email = email;
        this.name = name;
        this.photoURL = photoURL;
    }
}

/*
    响应
    * @param {int} code - 响应码, RESP_CODE
    * @param {string} message - 响应消息
    * @param {string} uid - 用户uid
*/
export class LoginResponseParams extends BaseResponseParams {
    constructor(code, uid) {
        super(code);
        this.uid = uid;
    }
}