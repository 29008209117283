<template>
    <ACalendar class="my-a-calendar-class" v-model:value="currentDayjs">
        <template #headerRender>
            <div style="display: flex; justify-content: center;" >
                <div style="display: flex; align-items: center">
                    <img src="../assets/calendar-left.png" style="cursor: pointer;"
                    @click="lastMonthHandler()"/>

                    <div style="display: inline-block;color: #FFFFFF;font-size: 18px;
                        padding-left: 12px;padding-right: 12px; padding-top: 20px; padding-bottom: 18px;">
                        {{ curMonthYear }}
                    </div>

                    <img src="../assets/calendar-right.png" style="padding-right: 14px; cursor: pointer;"
                    @click="nextMonthHandler()"/>
                    
                    <div style="color: #CF80EB; font-size: 14px; padding: 4px 12px;
                    border-radius: 4px; border: 1.5px solid #CF80EB; cursor: pointer;"
                    @click="todayHandler()">
                        Today
                    </div>
                </div>
            </div>
        </template>

        <template #dateFullCellRender="{ current }">
            <div class="date-value">
                {{ current.format('DD') }}
                <span class="red-dot" v-show="showRedDot(current)"></span>
            </div>
            
            <div v-for="(item, index) in currentEvents(current)" :key="index"
            v-show="index <= 1"
            :class="{
                'date-cell-1': currentEvents(current).length == 1,
                'date-cell-gt1': currentEvents(current).length > 1,
            }">
                <div>{{ getDateCellEventContent(currentEvents(current), index) }}</div>
            </div>
        </template>
    </ACalendar>
</template>

<script>
    import { defineComponent, ref } from 'vue';
    import { Calendar } from 'ant-design-vue';
    import { user, API_HOST } from "../js/global.js";
    import { QueryEventsRequestParams } from "../functions/queryEventsParams.js";
    import { EVENT_STATUS } from "../functions/commonUtil.js";
    import dayjs from "dayjs";

    export default defineComponent({
        name: 'MainCalendar',
        components: {
            ACalendar: Calendar
        },
        setup() {
            function headerRender() {
                return
            }
            const currentDayjs = ref();
            const todayDayjs = ref();

            return {currentDayjs, todayDayjs, headerRender}
        },
        data() {
            return {
                dateEventsMap: {},  // key: YYYY-MM-DD, value: [event1, event2, ...]
            }
        },
        computed: {
            curMonthYear() {
                if (!this.currentDayjs) {
                    return dayjs().format('MMM YYYY');
                }
                else {
                    return this.currentDayjs.format('MMM YYYY');
                }
            }
        },
        watch: {
            currentDayjs() {
                this.$emit('changeEventsList', this.currentDayjs, this.timestampToDate(this.currentDayjs));
            }
        },
        methods: {
            timestampToDate(timestamp) {
                return timestamp.startOf('day').format('YYYY-MM-DD');
            },

            currentEvents(current) {
                return this.dateEventsMap[this.timestampToDate(current)];
            },

            getDateCellEventContent(eventsList, index) {
                if (index == 0) {
                    return eventsList[index].event_info.event_name;
                }
                else {
                    if (eventsList.length == 2) {
                        return eventsList[index].event_info.event_name;
                    }
                    else {
                        return "+ " + (eventsList.length - 1) + ' events';
                    }
                }
            },

            async updateEvents(emitDate=null, emit=true) {
                this.dateEventsMap = {};
                // 获取日程
                const queryEventsReq = new QueryEventsRequestParams(user.uid, 0, 9999999999999, EVENT_STATUS.NONE);
                console.log(queryEventsReq);
                const cgiUrl = API_HOST + '/queryEvents';
                const body = JSON.stringify(queryEventsReq);
                await fetch(cgiUrl, {
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    console.log("结果：", response);
                    if (!response.ok) {
                        console.log(cgiUrl, '失败，错误码：' + response.status);
                        return;
                    }
                    return response.json(); // 解析JSON响应;
                })
                .then((responseData) => {
                    console.log('数据:', responseData);
                    
                    for(let i = 0; i < responseData.event_list.length; i++) {
                        const eventData = responseData.event_list[i];
                        const startTimestamp = eventData.event_info.event_start_date;
                        const endTimestamp = eventData.event_info.event_end_date;
                        var startDate = dayjs.unix(startTimestamp);
                        var endDate = dayjs.unix(endTimestamp);

                        for(let date = startDate; date.isBefore(endDate) || date.isSame(endDate); date = date.add(1, 'day')) {
                            let dateYYYYMMDD = date.format('YYYY-MM-DD');
                            if (!(dateYYYYMMDD in this.dateEventsMap)) {
                                this.dateEventsMap[dateYYYYMMDD] = [];
                            }
                            this.dateEventsMap[dateYYYYMMDD].push(eventData);
                        }
                    }
                    this.$emit('requestEvents', this.dateEventsMap);
                    if (!emitDate) {
                        this.currentDayjs = dayjs();
                    }
                    else {
                        this.currentDayjs = emitDate;
                        if (emit) {
                            this.$emit('changeEventsList', this.currentDayjs, this.timestampToDate(this.currentDayjs));
                        }
                    }
                })
                .catch((error) => {
                    console.log(cgiUrl, "失败：", error);
                });
            },

            showRedDot(current) {
                const events = this.currentEvents(current);
                if (events) {
                    for (let i = 0; i < events.length; i++) {
                        if (events[i].red_dot) {
                            return true;
                        }
                    }
                }
                return false;
            },

            lastMonthHandler() {
                // 切换到上个月的1号
                this.currentDayjs = this.currentDayjs.startOf('month').subtract(1, 'day').startOf('month');
            },

            nextMonthHandler() {
                // 切换到下个月的1号
                this.currentDayjs = this.currentDayjs.endOf('month').add(1, 'day');
            },

            todayHandler() {
                // 切换到今天
                this.currentDayjs = this.todayDayjs;
            },

            updateSelectedDate(date) {
                this.currentDayjs = date;
            }
        },
        beforeMount() {
            this.updateEvents();
            this.todayDayjs = dayjs();
        }
    })

</script>

<style scoped>
    .my-a-calendar-class {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .my-a-calendar-class ::v-deep .ant-picker-cell-in-view {
        opacity: 1 !important;
    }

    .my-a-calendar-class ::v-deep .ant-picker-cell {
        opacity: 0.4;
    }
    
    .ant-picker-calendar {
        background: transparent;
    }
    
    .my-a-calendar-class ::v-deep .ant-picker-panel {
        background: black;
        height: 0;
        flex: 1;
        text-align: start;
    }

    .my-a-calendar-class ::v-deep .ant-picker-date-panel {
        height: 100%;
    }

    .my-a-calendar-class ::v-deep .ant-picker-body {
        height: 100%;
        padding: 0;
    }

    .my-a-calendar-class ::v-deep .ant-picker-content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .my-a-calendar-class ::v-deep thead {
        display: none;
    }

    .my-a-calendar-class ::v-deep thead tr {
        display: flex;
    }

    .my-a-calendar-class ::v-deep .ant-picker-panel .ant-picker-body th {
        color: white;
        flex: 1;
        padding-inline-end: 0;
        padding: 4px 0 4px 0;
        text-align: center;
        border: 1px solid rgba(255, 255, 255, 0.10);
    }

    .my-a-calendar-class ::v-deep tbody {
        height: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .my-a-calendar-class ::v-deep tbody tr {
        height: 0;
        flex: 1;
        display: flex;
    }

    .my-a-calendar-class ::v-deep .ant-picker-content td {
        flex: 1;
        border: 1px solid rgba(255, 255, 255, 0.10);
        display: flex;
        flex-direction: column;
    }

    .my-a-calendar-class ::v-deep td.ant-picker-cell-selected {
        border: 1px #CF80EB solid;
    }

    .date-value {
        display: flex;
        align-items: center;
        color: white;
        font-size: 14px;
        padding: 8px 0 6px 8px;
        text-align: left;
    }

    .red-dot {
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-left: 4px;
        border-radius: 50%;
        background-color: red;
    }

    .date-cell-1 {
        height: 0;
        flex: 1;
        margin: 0 4px 4px 4px;
        padding: 4px;
        color: white;
        font-size: 10px;
        font-family: PingFang SC;
        background: rgba(133, 73, 253, 0.4);
        border-radius: 4px;
    }

    .date-cell-1 div {
        display: -webkit-box;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .date-cell-gt1 {
        height: 0;
        flex: 1;
        margin: 0 4px 4px 4px;
        padding: 4px;
        color: white;
        font-size: 10px;
        font-family: PingFang SC;
        background: rgba(133, 73, 253, 0.4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: left;
    }

    .date-cell-gt1 div {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

</style>