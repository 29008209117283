import { BaseRequestParams, BaseResponseParams } from "./commonUtil";

/*
    请求
    * @param {string} uid - 用户uid
    * @param {int} start_date - 开始日期
    * @param {int} end_date - 结束日期
    * @param {EVENT_STATUS} event_status, 日程状态，全部获取为EVENT_STATUS.NONE
*/

export class QueryEventsRequestParams extends BaseRequestParams {
    constructor(uid, start_date, end_date, event_status) {
        super(uid);
        this.start_date = start_date;
        this.end_date = end_date;
        this.event_status = event_status;
    }
}

/*
    响应
    * @param {int} code - 响应码, RESP_CODE
    * @param {string} message - 响应消息
    * @param {list} event_list - 日程列表，按照开始date-更新时间排序
*/
export class QueryEventsResponseParams extends BaseResponseParams {
    constructor(code, event_list) {
        super(code);
        this.event_list = event_list;
    }
}