<template>
    <div class="fill-info-container">
        <div class="fill-info-title">Please fill in personal info</div>
        <div class="fill-info-title-hint">so that we can help you find hosts that cater to your needs.</div>
        
        <div class="fill-info-card">
            <div style="color:#CF80EB;font-size: 18px;padding-bottom: 24px">Account Info</div>
            <div style="position: relative;">
                <img class="fill-info-avatar" :src="avatarURL"/>
                
                <div class="fill-info-my-info">
                    <div style="color: rgba(255, 255, 255, 0.60);font-size: 14px;line-height: 22px;">
                        User Name
                    </div>
                    <div style="color: #FFF;font-size: 16px;line-height: 22px;">
                        {{ userName }}
                    </div>
                </div>
                
                <div class="fill-info-my-info">
                    <div style="color: rgba(255, 255, 255, 0.60);font-size: 14px;line-height: 22px;">
                        Email
                    </div>
                    <div style="color: #FFF;font-size: 16px;line-height: 22px;">
                        {{ email }}
                    </div>
                </div>
            </div>
        </div>
        
        
        <div class="fill-info-card">
            <div style="color:#CF80EB;font-size: 18px;padding-bottom: 24px">Personal Info</div>
            
            <button class="fill-info-import-file" @click="{showUploadResume = true; a += 1;}">
                {{ resumeFilename ? "Manage Resume" : "Import Resume"}}
            </button>
            <div style="font-size: 14px;color: rgba(255, 255, 255, 0.60); display: flex; align-items: center;">
                Import resume to quickly fill in.
            </div>
            
            <div class="fill-info-card-name">Duration of Occupation<span class="required-item">*</span></div>
            <input v-model="seniority"
            class="fill-info-card-input" placeholder="Enter or auto-identify"/>
            
            <div class="fill-info-card-name">Background of Profession</div>
            <div style="width: 540px;">
                <textarea v-model="backgroundOfProfession"
                class="fill-info-card-textarea" style="width: 100%; height: 100px;" 
                maxlength="100" placeholder="Enter or auto-identify"></textarea>
                <div class="fill-info-card-input-pre">{{ backgroundOfProfession.length }} / 100</div>
            </div>

            <div class="fill-info-card-name">Performance Experience</div>
            <div style="width: 540px;">
                <textarea v-model="performanceExperience"
                class="fill-info-card-textarea" style="width: 100%; height: 100px;" 
                maxlength="100" placeholder="Enter or auto-identify"></textarea>
                <div class="fill-info-card-input-pre">{{ performanceExperience.length }} / 100</div>
            </div>

            <div class="fill-info-card-name">Music Style</div>
            <div v-show="!confirmedWorks">
                <button class="fill-info-import-file" @click="{showUploadWorks = true; a += 1;}">Import Works</button>
                <div style="font-size: 14px;color: rgba(255, 255, 255, 0.60); display: flex; align-items: center;">
                    AI will analyze your works and generate a music style profile
                </div>
            </div>
            <div v-show="confirmedWorks">
                <div id="music-profile-legend-container" style="width: 400px;"></div>
                <button class="fill-info-import-file" style="margin-left: 32px; margin-top: 32px"
                 @click="{showUploadWorks = true; a += 1;}">Manage Works</button>
            </div>
            
        </div>
        
        <div style="display: flex; justify-content: center; margin-bottom: 48px;"
        @click="updatePersonalInfo()">
            <button class="fill-info-import-file"
            :class="{'submit-disable': submitDisable}">Confirm and Summit</button>
        </div>

        <!-- 上传个人简历 -->
        <div class="upload-file" v-show="showUploadResume">
            <div class="upload-file-container">
                <img class="close-button" src="../assets/Close.svg"
                @click="showUploadResume = false"/>

                <div class="upload-title">Upload File</div>
                
                <div class="upload-list-container">
                    <a-upload-dragger
                        v-model:fileList="resumeFileList"
                        name="resumeFiles"
                        accept=".pdf"
                        :maxCount="1"
                        :customRequest="customUploadFile"
                        :multiple="true"
                        v-if="a"
                    >
                        <div style="text-align: center;"><img src="../assets/inbox.svg" style="width: 48px; height: 48px;"/></div>

                        <div style="margin-top: 20px;">
                            <p class="upload-text">Click or drag file to this area</p>
                            <p class="upload-hint">Support pdf, 1 file</p>
                        </div>

                        <template #itemRender="{file, actions}">
                            <div :class="['upload-list-item', 
                                          {'upload-list-item-error': file.status === 'error'}]">
                                <img src="../assets/file-pdf.svg" 
                                :class="{'upload-list-item-unsuccessful': file.status != 'done'}"
                                style="width: 48px; height: 48px"/>

                                <span :class="['upload-list-item-name',
                                                {'upload-list-item-name-error': file.status === 'error'}]">
                                    {{ file.name }}
                                </span>

                                <span class="upload-list-item-progress-format" v-show="file.status === 'uploading'">{{ file.percent }}%</span>
                                <span class="upload-list-item-hint-error" v-show="file.status === 'error'">Upload Error</span>
                                
                                <div class="upload-list-item-actions" @click="actions.remove">
                                    <img src="../assets/delete-icon.svg" style="width: 14px; height: 14px;"/>
                                </div>
                                
                                <div class="upload-list-item-progress" v-show="file.status === 'uploading'" :style="{width: file.percent + '%'}"></div>
                            </div>
                        </template>
                    </a-upload-dragger>
                </div>

                <div class="upload-import-container">
                    <button class="upload-import" 
                    :class="{'btn-disable': !isOneValidInfoFile}" 
                    @click="importFile(true, isOneValidInfoFile)">Import</button>
                </div>
            </div>
        </div>

        <!-- 上传工作经历 -->
        <div class="upload-file" v-show="showUploadWorks">
            <div class="upload-file-container">
                <img class="close-button" src="../assets/Close.svg"
                @click="showUploadWorks = false"/>

                <div class="upload-title">Upload File</div>
                
                <div class="upload-list-container">
                    <a-upload-dragger
                        v-model:fileList="workList"
                        name="workFiles"
                        accept=".mp4,.mp3,.wav"
                        :customRequest="customUploadMusic"
                        :multiple="true"
                        v-if="a"
                    >
                        <div style="text-align: center;"><img src="../assets/inbox.svg" style="width: 48px; height: 48px;"/></div>

                        <div style="margin-top: 20px;">
                            <p class="upload-text">Click or drag file to this area</p>
                            <p class="upload-hint">Support mp3, mp4, wav</p>
                        </div>

                        <template #itemRender="{file, actions}">
                            <div :class="['upload-list-item', 
                                          {'upload-list-item-error': file.status === 'error'}]">
                                <img src="../assets/video.svg"
                                :class="{'upload-list-item-unsuccessful': file.status != 'done'}"
                                style="width: 48px; height: 48px"/>

                                <span :class="['upload-list-item-name',
                                                {'upload-list-item-name-error': file.status === 'error'}]">
                                    {{ file.name }}
                                </span>

                                <span class="upload-list-item-progress-format" v-show="file.status === 'uploading'">{{ file.percent }}%</span>
                                <span class="upload-list-item-hint-error" v-show="file.status === 'error'">Upload Error</span>
                                
                                <div class="upload-list-item-actions" @click="actions.remove">
                                    <img src="../assets/delete-icon.svg" style="width: 14px; height: 14px;"/>
                                </div>
                                
                                <div class="upload-list-item-progress" v-show="file.status === 'uploading'" :style="{width: file.percent + '%'}"></div>
                            </div>
                        </template>
                    </a-upload-dragger>
                </div>

                <div class="upload-import-container">
                    <button class="upload-import" 
                    :class="{'btn-disable': !hasValidMusicFile}" 
                    @click="importFile(false, hasValidMusicFile)">Import</button>
                </div>
            </div>
        </div>

        <!-- 文件解析中 -->
        <div class="analyse-file" v-show="showAnalyzingFile">
            <div class="analyse-file-container">
                <img src="../assets/loading.gif" style="width: 100%;"/>
                <div style="margin-top: 48px; margin-bottom: 77px;">
                    <div style="text-align: center; color: white; font-size: 21px; font-family: PingFang SC; font-weight: 400;">Analyzing File...</div>
                    <div style="margin-top: 20px; text-align: center; color: #CF80EB; font-size: 18px; font-family: PingFang SC; font-weight: 400;">{{ analyseProgress }}%</div>
                </div>
            </div>
        </div>
    
        <!-- 个人简历解析完成 -->
        <div class="analysis-result" v-show="showResumeAnalysis">
            <div class="analysis-result-container">
                <img class="close-button" src="../assets/Close.svg"
                @click="showResumeAnalysis = false"/>
                
                <div style="display: flex; justify-content: center; align-items: center;">
                    <img src="../assets/login-circle.svg" style="width: 32px; height: 32px; "/>
                    <span style="margin-left: 16px; color: white; font-size: 21px; font-family: PingFang SC;">Analysis Done</span>
                </div>
                
                <div class="fill-info-card-name">Duration of Occupation</div>
                <input v-model="seniorityTemp"
                class="fill-info-card-input" placeholder="Enter or auto-identify"
                style="width: 100%;"/>

                <div class="fill-info-card-name" style="padding-top: 24px;">Background of Profession</div>
                <input v-model="backgroundOfProfessionTemp"
                class="fill-info-card-input" placeholder="Enter or auto-identify"
                style="width: 100%;"/>

                <div class="fill-info-card-name" style="padding-top: 24px;">Performance Experience</div>
                <div style="width: 100%;">
                    <textarea v-model="performanceExperienceTemp"
                    class="fill-info-card-textarea" style="width: 100%; height: 80px;" 
                    maxlength="100" placeholder="Enter or auto-identify"></textarea>
                    <div class="fill-info-card-input-pre">{{ performanceExperienceTemp.length }} / 100</div>
                </div>

                <div style="display: flex; justify-content: center;">
                    <button @click="fillResume()"
                    class="fill-info-import-file" style="width: 200px; margin-top: 20px;">Confirm and Fill in</button>
                </div>
            </div>
        </div>

        <!-- 工作经历解析完成 -->
        <div class="analysis-result" v-show="showWorksAnalysis">
            <div class="analysis-result-container" style="padding-top: 24px; width: 60%;">
                <img class="close-button" style="right: 32px;"
                src="../assets/Close.svg"
                @click="showWorksAnalysis = false"/>
                
                <div>
                    <span style="color: white; font-size: 21px; font-family: PingFang SC;">My Music Profile</span>
                </div>

                <div id="music-profile-label-container" style="text-align: center;"></div>
                
                <div style="display: flex; justify-content: center;">
                    <button @click="fillWorks"
                    class="fill-info-import-file" style="width: 200px; margin-top: 20px">Confirm and Fill in</button>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import { Pie } from '@antv/g2plot';
    import { UpdateUserInfoRequestParams } from "../functions/updateUserInfoParams.js";
    import { QueryUserInfoRequestParams, QUERY_USER_INFO_TYPE } from "@/functions/queryUserInfoParams.js";
    import { user, API_HOST } from "../js/global.js"
    import { RESP_CODE } from "../functions/commonUtil.js";
    import { STORAGE_NAME, getFilePath, getFolderPath, getDownloadURLByPath, uploadFile } from "../functions/fileUpDownload.js";
    import { FileRecognizeRequestParams, FILE_RECOGNIZE_TYPE } from "../functions/fileRecognizeParams.js";
    import { MusicRecognizeRequestParams } from "../functions/musicRecognizeParams.js";

    export default {
        name: "FillPersonalInfoPage",
        data() {
            return {
                a: 0,  // 用于重新渲染a-upload控件
                userName: "",  // 用户名
                email: "",  // 邮箱
                avatarURL: "",  // 头像地址
                seniority: "",
                seniorityTemp: "This is an example of a filled text area.",
                seniorityOnServer: "",
                backgroundOfProfession: "",
                backgroundOfProfessionTemp: "This is an example of a filled text area.",
                backgroundOfProfessionOnServer: "",
                performanceExperience: "",
                performanceExperienceTemp: "A design system for enterprise-level products. Create an efficient and enjoyable work experience.",
                performanceExperienceOnServer: "",
                // resumeFileList: [
                //     {
                //         uid: '-1',
                //         name: 'xxx.pdf',
                //         status: 'done',
                //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     },
                //     {
                //         uid: '-2',
                //         name: 'xxx.pdf',
                //         status: 'uploading',
                //         percent: 20,
                //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     },
                //     {
                //         uid: '-3',
                //         name: 'xxx.pdf',
                //         status: 'error',
                //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     }
                // ],
                resumeFileList: [],
                resumeFilenameOnServer: "",
                // workList: [
                //     {
                //         uid: '-1',
                //         name: 'xxx.pdf',
                //         status: 'error',
                //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     },
                //     {
                //         uid: '-2',
                //         name: 'xxx.pdf',
                //         status: 'uploading',
                //         percent: 20,
                //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     },
                //     {
                //         uid: '-3',
                //         name: 'xxx.pdf',
                //         status: 'done',
                //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     }
                // ],
                workList: [],
                workListOnServer: null,
                
                // musicStyles: [
                //     {type: 'Jazz 27%', value: 27},
                //     {type: 'Jazz2 27%', value: 23},
                //     {type: 'Ballad 27%', value: 17},
                //     {type: 'Blues 27%', value: 20},
                //     {type: 'Others 27%', value: 13},
                // ],
                musicStyles: [],
                musicStylesOnServer: [],

                musicStylesTemp: [],

                piePlot: null,  // 音乐文件解析后的饼图
                piePlotLegend: null,  // 个人信息页的饼图

                analyseProgress: 0,
                
                showUploadResume: false,
                showUploadWorks: false,
                showAnalyzingFile: false,
                showResumeAnalysis: false,
                showWorksAnalysis: false,
                confirmedWorks: false,
                hasUpdated: false,  // 非第一次上传，该值为true
                hasChanged: false,  // 若资料有改动，该值为true
            }
        },
        watch: {
            changeData: {
                handler() {
                    if (this.seniority != this.seniorityOnServer ||
                        this.backgroundOfProfession != this.backgroundOfProfessionOnServer ||
                        this.performanceExperience != this.performanceExperienceOnServer ||
                        this.resumeFilename != this.resumeFilenameOnServer ||
                        JSON.stringify(this.musicFilenameList) != JSON.stringify(this.workListOnServer)) {
                            this.hasChanged = true;
                    }
                },
                deep: true
            }
        },
        computed: {
            changeData() {
                const {seniority, backgroundOfProfession, performanceExperience, resumeFileList, workList} = this;
                return {seniority, backgroundOfProfession, performanceExperience, resumeFileList, workList}
            },

            resumeFilename() {
                var filename = null;
                for (let i = 0; i < this.resumeFileList.length; i++) {
                    if (this.resumeFileList[i].status == "done") {
                        filename = this.resumeFileList[i].utf8Name;
                        break;
                    }
                }
                return filename;
            },

            musicFilenameList() {
                var filenameList = [];
                for (let i = 0; i < this.workList.length; i++) {
                    if (this.workList[i].status == "done") {
                        filenameList.push(this.workList[i].utf8Name);
                    }
                }
                if (filenameList.length == 0) {
                    filenameList = null;
                }
                return filenameList;
            },
            
            // musicStyles() {
            //     var styleList = [];
            //     for (let i = 0; i < this.)
            //     if (styleList.length == 0) {
            //         styleList = null;
            //     }
            //     return styleList;
            // },

            isOneValidInfoFile() {
                var count = 0;
                for(let i = 0; i < this.resumeFileList.length; i++) {
                    if (this.resumeFileList[i].status == "done") {
                        count += 1;
                    }
                }
                if (count == 1) {
                    return true;
                }
                return false;
            },

            hasValidMusicFile() {
                var count = 0;
                for(let i = 0; i < this.workList.length; i++) {
                    if (this.workList[i].status == "done") {
                        count += 1;
                    }
                }
                if (count >= 1) {
                    return true;
                }
                return false;
            },

            submitDisable() {
                return (!this.seniority) || (!this.hasChanged);
            },
        },
        methods: {
            setTopBarVisible(visible, refreshUserInfo=false, showExit=false) {
                this.$emit('setTopBarVisible', visible, refreshUserInfo, showExit);
            },
            toggleRole(value) {
                for(var i = 0; i < this.roles.length; i++) {
                    if (this.roles[i].value == value) {
                        this.roles[i].isChosen = true
                    }
                    else {
                        this.roles[i].isChosen = false
                    }
                }
            },

            importFile(isResume, isValid) {
                
                if (!isValid) {
                    return;
                }

                if (isResume) {  // 解析个人简历
                    const fileRecognizeReq = new FileRecognizeRequestParams(user.uid, this.resumeFilename, FILE_RECOGNIZE_TYPE.MUSICIAN_INFO);
                    const cgiUrl = API_HOST + '/fileRecognize';
                    const body = JSON.stringify(fileRecognizeReq);
                    fetch(cgiUrl, {
                        method: 'POST',
                        body: body,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then((response) => {
                        console.log("结果：", response);
                        if (!response.ok) {
                            console.log(cgiUrl, '失败，错误码：' + response.status);
                            return;
                        }
                        return response.json();
                    })
                    .then((responseData) => {
                        console.log('数据:', responseData);
                        if (responseData.code == RESP_CODE.SUCCESS) {
                            this.seniorityTemp = responseData.occupationTime;
                            this.backgroundOfProfessionTemp = responseData.musicBackground;
                            this.performanceExperienceTemp = responseData.performanceExperience;

                            this.showUploadResume = false;
                            this.analyseProgress = 0;
                            this.showAnalyzingFile = true;
                            let progress = 0;
                            const increment = 100 / (3000 / 10);  // 等待3秒(0->100)，每10毫秒增加的值
                            const intervalId = setInterval(() => {
                                if (progress < 100) {
                                    progress += increment;
                                    this.analyseProgress = Math.round(progress);
                                }
                                else {
                                    this.analyseProgress = 100;
                                    clearInterval(intervalId);
                                    this.showAnalyzingFile = false;
                                    this.showResumeAnalysis = true;
                                }
                            }, 10);
                        }
                    })
                    .catch((error) => {
                        console.log(cgiUrl, "失败：", error);
                    });
                    
                }
                else {  // 解析个人作品
                    const musicRecognizeReq = new MusicRecognizeRequestParams(user.uid, this.musicFilenameList);
                    console.log(musicRecognizeReq);
                    const cgiUrl = API_HOST + '/musicRecognize';
                    const body = JSON.stringify(musicRecognizeReq);
                    fetch(cgiUrl, {
                        method: 'POST',
                        body: body,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then((response) => {
                        console.log("结果：", response);
                        if (!response.ok) {
                            console.log(cgiUrl, '失败，错误码：' + response.status);
                            return;
                        }
                        return response.json();
                    })
                    .then((responseData) => {
                        console.log('数据:', responseData);
                        if (responseData.code == RESP_CODE.SUCCESS) {
                            this.musicStylesTemp = [];
                            for (let k in responseData.musicStyle) {
                                this.musicStylesTemp.push({
                                    type: k,
                                    value: Math.round(responseData.musicStyle[k]),
                                });
                            }

                            this.showUploadWorks = false;
                            this.analyseProgress = 0;
                            this.showAnalyzingFile = true;
                            let progress = 0;
                            const increment = 100 / (3000 / 10);  // 等待3秒(0->100)，每10毫秒增加的值
                            const intervalId = setInterval(() => {
                                if (progress < 100) {
                                    progress += increment;
                                    this.analyseProgress = Math.round(progress);
                                }
                                else {
                                    this.analyseProgress = 100;
                                    clearInterval(intervalId);
                                    this.showAnalyzingFile = false;
                                    this.showWorksAnalysis = true;
                                    this.musicStyleTempPieRender();
                                }
                            }, 10);
                        }
                    })
                    .catch((error) => {
                        console.log(cgiUrl, "失败：", error);
                    });
                }
            },

            fillResume() {
                this.seniority = this.seniorityTemp
                this.backgroundOfProfession = this.backgroundOfProfessionTemp
                this.performanceExperience = this.performanceExperienceTemp
                this.showResumeAnalysis = false
            },

            fillWorks() {
                this.showWorksAnalysis = false;
                this.confirmedWorks = true;
                this.musicStyles = JSON.parse(JSON.stringify(this.musicStylesTemp));
                this.musicStylePieLegendRender();
            },

            musicStyleTempPieRender() {
                if (!this.piePlot) {
                    this.piePlot = new Pie('music-profile-label-container',{
                        data: this.musicStylesTemp,
                        width: 450,
                        height: 400,
                        padding: [60, 0, 60, 0],
                        innerRadius: 0.6,
                        angleField: 'value',
                        colorField: 'type',
                        // color: ['rgb(207,128,235)', 'rgb(133,73,253)', 'rgb(69,197,237)', 'rgb(250,234,144)', 'rgb(157,91,235)'],
                        pieStyle: {
                            stroke: '#222222',
                            lineWidth: 1,
                        },
                        meta: {
                            value: {
                                formatter: (v) => {
                                    return `${v}%`
                                }
                            }
                        },
                        legend: false,
                        statistic: {
                            title: false,
                            content: false,
                        },
                        label: {
                            type: 'outer-center',
                            labelLine: null,
                            layout: 'limitInShape',
                            formatter: (data) => {
                                return `${data.type} ${data.value}%`
                            },
                            style: {
                                fill: 'white',
                                fontSize: 14,
                            }
                        }
                    });
                    this.piePlot.render();
                }
                else {
                    this.piePlot.changeData(this.musicStylesTemp);
                }
            },

            musicStylePieLegendRender() {
                if (!this.piePlotLegend) {
                    this.piePlotLegend = new Pie('music-profile-legend-container',{
                        data: this.musicStyles,
                        width: 150,
                        height: 150,
                        innerRadius: 0.6,
                        angleField: 'value',
                        colorField: `type`,
                        // color: ['rgb(207,128,235)', 'rgb(133,73,253)', 'rgb(69,197,237)', 'rgb(250,234,144)', 'rgb(157,91,235)'],
                        pieStyle: {
                            stroke: '#222222',
                            lineWidth: 1,
                        },
                        meta: {
                            value: {
                                formatter: (v) => {
                                    return `${v}%`
                                }
                            }
                        },
                        legend: {
                            layout: 'vertical',
                            position: 'right',
                            offsetX: -20,
                            itemValue: {
                                formatter: (text, item) => {
                                    const items = this.musicStyles.filter((d) => d.type === item.value);
                                    return items[0].value + "%";
                                }
                            }
                        },
                        statistic: {
                            title: false,
                            content: false,
                        },
                        label: false
                    })
                    this.piePlotLegend.render();
                }
                else {
                    this.piePlotLegend.changeData(this.musicStyles);
                }
            },

            // 查询个人信息
            queryPersonalInfo() {
                const queryUserInfoReq = new QueryUserInfoRequestParams(user.uid, QUERY_USER_INFO_TYPE.ALL_INFO);
                const cgiUrl = API_HOST + '/queryUserInfo';
                const body = JSON.stringify(queryUserInfoReq);
                fetch(cgiUrl, {
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    console.log("结果：", response);
                    if (!response.ok) {
                        console.log(cgiUrl, '失败，错误码：' + response.status);
                        return;
                    }
                    return response.json(); // 解析JSON响应;
                })
                .then((responseData) => {
                    console.log('数据:', responseData);
                    if (responseData.code == RESP_CODE.SUCCESS) {
                        const path = getFilePath(user.uid, STORAGE_NAME.AVATAR, responseData.avatar);
                        getDownloadURLByPath(path).then((url) => {
                            this.avatarURL = url;
                        });
                        this.userName = responseData.name;
                        this.email = responseData.email;
                        this.seniority = responseData.occupation_time != null ? responseData.occupation_time : "";
                        this.seniorityOnServer = this.seniority;
                        this.backgroundOfProfession = responseData.music_background != null ? responseData.music_background : "";
                        this.backgroundOfProfessionOnServer = this.backgroundOfProfession;
                        this.performanceExperience = responseData.performance_experience != null ? responseData.performance_experience : "";
                        this.performanceExperienceOnServer = this.performanceExperience;

                        if (responseData.info_file) {
                            this.resumeFileList.push(
                                {
                                    uid: '1',
                                    utf8Name: responseData.info_file,
                                    name: decodeURIComponent(responseData.info_file),
                                    status: 'done',
                                }
                            );
                        }
                        this.resumeFilenameOnServer = responseData.info_file;

                        if (responseData.musician_files) {
                            this.workList = [];
                            this.workListOnServer = [];

                            for (let i = 0; i < responseData.musician_files.length; i++) {
                                this.workList.push(
                                    {
                                        uid: responseData.musician_files[i],
                                        utf8Name: responseData.musician_files[i],
                                        name: decodeURIComponent(responseData.musician_files[i]),
                                        status: 'done',
                                    }
                                );
                                this.workListOnServer.push(responseData.musician_files[i]);
                            }
                        }

                        if (responseData.music_style) {
                            this.musicStyles = [];
                            for (let k in responseData.music_style) {
                                this.musicStyles.push({
                                    type: k,
                                    value: responseData.music_style[k],
                                })
                            }
                            this.musicStylesOnServer = JSON.parse(JSON.stringify(this.musicStyles));
                            this.confirmedWorks = true;
                            this.musicStylePieLegendRender();
                        }
                        
                        this.hasChanged = false;
                        if (this.seniority) {
                            this.hasUpdated = true;
                            user.isFirstFill = false;
                        }
                    }
                })
                .catch((error) => {
                    console.log(cgiUrl, "失败：", error);
                });
            },

            // 上传个人信息
            updatePersonalInfo() {
                if (this.submitDisable) {
                    return
                }

                const updateUserInfoReq = new UpdateUserInfoRequestParams(user.uid);
                updateUserInfoReq.info_file = this.resumeFilename;  //信息文件
                updateUserInfoReq.musician_files = this.musicFilenameList;  //音乐文件
                updateUserInfoReq.occupation_time = this.seniority;  //从业时长
                updateUserInfoReq.music_background = this.backgroundOfProfession;  //音乐背景
                updateUserInfoReq.performance_experience = this.performanceExperience;  //演出经历
                let musicStyles = null;
                for (let i = 0; i < this.musicStyles.length; i++) {
                    if (!musicStyles) {
                        musicStyles = {};
                    }
                    musicStyles[this.musicStyles[i].type] = this.musicStyles[i].value;
                }
                updateUserInfoReq.music_style = musicStyles;  //音乐风格
                const cgiUrl = API_HOST + '/updateUserInfo';
                const body = JSON.stringify(updateUserInfoReq);
                fetch(cgiUrl, {
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    console.log("结果：", response);
                    if (!response.ok) {
                        console.log(cgiUrl, '失败，错误码：' + response.status);
                        return;
                    }
                    return response.json();
                })
                .then((responseData) => {
                    console.log('数据:', responseData);
                    if (responseData.code == RESP_CODE.SUCCESS) {
                        this.seniorityOnServer = this.seniority;
                        this.backgroundOfProfessionOnServer = this.backgroundOfProfession;
                        this.performanceExperienceOnServer = this.performanceExperience;
                        this.resumeFilenameOnServer = this.resumeFilename;
                        this.musicStylesOnServer = JSON.parse(JSON.stringify(this.musicStyles));
                        this.hasChanged = false;

                        if (!this.hasUpdated) {
                            this.setTopBarVisible(true, true, true);
                            this.$router.push("diary");
                        }
                        this.hasUpdated = true;
                        user.isFirstFill = false;
                    }
                })
                .catch((error) => {
                    console.log(cgiUrl, "失败：", error);
                });
            },

            // 上传文件
            customUploadFile(options) {
                console.log(options);
                const folderPath = getFolderPath(user.uid, STORAGE_NAME.FILE);
                uploadFile(folderPath, options.file, (e) => {
                    options.onProgress({ percent: Math.round(e)});
                })
                .then((response) => {
                    options.file.utf8Name = response;
                    options.onSuccess(response);
                })
                .catch((err) => {
                    console.log(err);
                    options.onError(err);
                });
            },

            // 上传音乐
            customUploadMusic(options) {
                console.log(options);
                const folderPath = getFolderPath(user.uid, STORAGE_NAME.MUSIC);
                uploadFile(folderPath, options.file, (e) => {
                    options.onProgress({ percent: Math.round(e)});
                })
                .then((response) => {
                    options.file.utf8Name = response;
                    options.onSuccess(response);
                })
                .catch((err) => {
                    console.log(err);
                    options.onError(err);
                });
            },
        },
        created() {
            this.queryPersonalInfo();
        },
        mounted() {
            this.setTopBarVisible(true, true, true);
        }
    }
</script>

<style scoped>
    .fill-info-card-role {
        display: inline-block;
        
        padding: 5px 16px;
        gap: 4px;
        border-radius: 2px 0px 0px 2px;
        border: 1px solid rgba(255, 255, 255, 0.40);
        background: #262626;
        color: rgba(255, 255, 255, 0.60);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        
        margin-right: 14px;
    }

    .fill-info-card-role-choose {
        border: 1px solid #CF80EB;
        color: #CF80EB;
    }
    
    .fill-info-card-input-pre {
        color: rgba(255, 255, 255, 0.40);
        text-align: right;
        
        /* Body/regular */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
    }
    
    .fill-info-card-input {
        display: flex;
        width: 540px;
        padding: 6px 12px;
        align-items: center;
        border-radius: 4px;
        border: 1px solid transparent;
        background: #FFFFFF1A;
        color: white;
        font-size: 14px;
    }

    .fill-info-card-textarea {
        display: flex;
        width: 540px;
        padding: 6px 12px;
        align-items: center;
        border-radius: 2px;
        border: 1px solid transparent;
        background: #FFFFFF1A;
        color: white;
        resize: none;
        font-size: 14px;
        line-height: 1.5em;
    }

    .fill-info-card-input:focus, .fill-info-card-textarea:focus {
        outline: none;
        border: 1px solid rgb(207, 128, 235);
    }
    
    .fill-info-card-name {
        color: rgba(255, 255, 255, 0.85);
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; 
        padding-top: 30px;
        padding-bottom: 12px
    }
    
    .fill-info-import-file {
        /* width: 110px; */
        height: 40px;
        display: inline-flex;
        padding: 11px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        background: linear-gradient(270deg, #8549FD 0%, #9E5BF7 32.29%, #CF80EB 100%);
        border: 0px;
        color: #FFF;
        font-size: 16px;
        margin-bottom: 12px;
        cursor: pointer;
    }
    
    .fill-info-my-info {
        display: inline-block;
        vertical-align: middle;
        height: 50px;
        padding-right: 100px;
    }
    
    .fill-info-avatar {
        vertical-align: middle;
        margin-right: 72px;
        display: inline-block;
        width: 100px;
        height: 100px;
        flex-shrink: 0;
        border-radius: 100px;
        background: lightgray 50% / cover no-repeat;
    }
    
    .fill-info-card {
        width: 100%;
        flex-shrink: 0;
        padding-bottom: 24px;
        margin-bottom: 32px;
        padding-left: 32px;
        padding-right: 32px;
        padding-top: 24px;
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.10);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.05) 100%);
    }
    
    .fill-info-title-hint {
        color: #FFF;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-bottom: 28px;
    }
    
    .fill-info-title {
        font-family: PingFang SC;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 392px;
        background: linear-gradient(270deg, #8549FD 0%, #9E5BF7 32.29%, #CF80EB 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-bottom: 10px;
    }

    .required-item {
        color: rgb(248, 59, 99)
    }
    
    .fill-info-container {
        position: relative;
        /* z-index: 1; */
        padding-left: 180px;
        padding-right: 180px;
        padding-top: 80px;
        width: 100%;
        background-color: black;
    }

    .required-item {
        color: rgb(248, 59, 99)
    }

    .upload-file, .analyse-file, .analysis-result {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        background: rgba(0,0,0,0.4);
        backdrop-filter: blur(10px);
        font-family: PingFang SC;
        word-wrap: break-word;
    }

    .upload-file-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40%;
        padding: 24px 32px 40px 32px;
        background: #222222;
        border-radius: 4px;
        border: 2px rgba(255, 255, 255, 0.10) solid
    }

    .close-button {
        display: inline-block;
        position: absolute;
        top: 20px;
        right: 24px;
        width: 14px;
        height: 14px;
        cursor: pointer;
    }

    .upload-title {
        margin-bottom: 24px; 
        color: #CF80EB; 
        font-size: 18px; 
        font-family: PingFang SC; 
        font-weight: 500;
    }

    .upload-list-container {
        max-height: 450px;
        overflow: auto;
    }

    .upload-list-container::-webkit-scrollbar {
        width: 0;
    }

    .upload-list-container ::v-deep .ant-upload-drag {
        padding: 16px 0px;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 2px;
        border: 0;
    }

    .upload-text {
        margin: 0; 
        color: rgba(255, 255, 255, 0.85); 
        font-size: 16px; 
        font-family: PingFang SC;
    }

    .upload-hint {
        margin: 0; 
        color: rgba(255, 255, 255, 0.45); 
        font-size: 14px; 
        font-family: PingFang SC;
    }

    .upload-list-item {
        position: relative;
        height: 66px;
        padding: 8px;
        margin-top: 20px;
        border: 1px solid rgba(255, 255, 255, 0.25);
        border-radius: 2px;
        font-size: 14px;
        transition: background-color 0.3s;
        display: flex;
        align-items: center;
    }

    .upload-list-item-error {
        border: 1px solid #ff4d4f;
        border-radius: 2px;
    }

    .upload-list-item-unsuccessful {
        opacity: 0.4;
    }

    .upload-list-item-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 8px;
        line-height: 1.5714285714285714;
        flex: auto;
        transition: all 0.3s;
        margin-left: 8px;
        color: #CF80EB;
        font-size: 14px;
        font-family: PingFang SC;
    }

    .upload-list-item-name-error, .upload-list-item-hint-error {
        color: #ff4d4f;
    }

    .upload-list-item-progress-format {
        color: #CF80EB;
        font-size: 14px;
        font-family: PingFang SC;
    }

    .upload-list-item-actions {
        display: flex;
        padding: 3px 5px;
        margin-left: 8px;
        align-items: center;
        cursor: pointer;
    }

    .upload-list-item-progress {
        position: absolute;
        left: 0; 
        bottom: 0;
        height: 3px;  
        background: linear-gradient(270deg, #8549FD 0%, #9E5BF7 32.29%, #CF80EB 100%);
    }

    .upload-import-container {
        display: flex; 
        justify-content: center; 
        margin-top: 48px; 
        margin-bottom: 8px;
    }

    .upload-import {
        width: 200px;
        height: 40px;
        display: inline-flex;
        padding: 11px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        background: linear-gradient(270deg, #8549FD 0%, #9E5BF7 32.29%, #CF80EB 100%);
        border: 0px;
        color: #FFF;
        font-size: 16px;
        cursor: pointer;
    }

    .submit-disable {
        opacity: 0.4;
        cursor: default;
    }

    .analyse-file-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40%;
        background: #262626;
        border-radius: 4px;
        border: 2px rgba(255, 255, 255, 0.10) solid
    }

    .analysis-result-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40%;
        padding: 48px 32px 40px 32px;
        background: #222222;
        border-radius: 4px;
        border: 2px rgba(255, 255, 255, 0.10) solid
    }

    .btn-disable {
        opacity: 0.4;
        cursor: default;
    }
</style>