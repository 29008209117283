<template>
    <div style="display: flex; align-items: center;">
        <div class="progress-bar">
            <div class="progress-bar-outer">
                <div class="progress-bar-inner" :style="barStyle"></div>
            </div>
        </div>
        <div class="progress-text">{{ this.percentage + "%" }}</div>
    </div>
</template>

<script>
export default {
    props: {
        percentage: {
            type: Number,
            default: 10
        }
    },
    computed: {
        barStyle() {
            return {
                width: this.percentage + "%"
            }
        },
    }
}
</script>

<style scoped>
    .progress-bar{
        display:inline-block;
        width: 65%;
        margin-right: 8px;
    }

    .progress-bar-outer {
        width: 100%;
        height: 8px;
        margin: auto;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 2px;
    }

    .progress-bar-inner {
        height: 100%;
        border-radius: 2px;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        background-image: linear-gradient(to right, #c775e9, #7941fc);
        transition: width 0.6s ease;
        line-height: 80%;
    }

    .progress-text {
        display: inline-block;
        color: #CF80EB;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
    }
</style>