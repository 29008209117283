// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBjnnP0195XgZCTLdA5O5GunPCsh018GI4",
  authDomain: "aimusicagent.firebaseapp.com",
  projectId: "aimusicagent",
  storageBucket: "aimusicagent.appspot.com",
  messagingSenderId: "725470355766",
  appId: "1:725470355766:web:2717e5b74a4fb78b961397",
  measurementId: "G-DWJWKC62NT",
  databaseURL: "https://aimusicagent-default-rtdb.firebaseio.com/",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
// const fbDatabase = getDatabase(firebaseApp);
const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider('6LdgefcoAAAAAB_2ZYO2ax96DlOKPykVaep_yHwF'),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

export default firebaseApp;