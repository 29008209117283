import { BaseRequestParams, BaseResponseParams } from "./commonUtil";

/*
    请求
    * @param {string} uid - 用户uid
    * @param {string} musicFiles - 音乐文件路径
*/
export class MusicRecognizeRequestParams extends BaseRequestParams {
    constructor(uid, musicFiles) {
        super(uid);
        this.musicFiles = musicFiles;
    }
}

/*
    响应
    * @param {int} code - 响应码, 
    * @param {string} message - 响应消息
    * @param {Map} musicStyle - 总体风格, key为风格名, value为风格值
    * @param {Map} musicResult - 详细风格, key为音乐文件名, value为音乐风格Map
*/
export class MusicRecognizeResponseParams extends BaseResponseParams {
    constructor(code, musicStyle, musicResult) {
        super(code);
        this.musicStyle = musicStyle;
        this.musicResult = musicResult;
    }
}