<template>
    <div>
        <a-card title="对话框" style="width: 800px;">
            <div class="scrollable-messages" style="height: 200px; overflow-y: auto;">
                <div v-for="(message, index) in messages" :key="index" class="message-row">
                    <div class="message" :class="message.sender === 'me' ? 'message-me' : 'message-other'">
                        <a-text>{{ message.content }}</a-text>
                    </div>
                </div>
            </div>
        </a-card>
    </div>
</template>

<script>
    import {defineComponent, ref} from 'vue'
    import {Card, Typography} from 'ant-design-vue';

    export default defineComponent({
        name: "AiCommunication",
        setup() {
            const messages = ref([
                {sender: 'other', content: '你好！'},
                {sender: 'me', content: '你好，很高兴认识你！'},
                {sender: 'other', content: '我也很高兴认识你！'},
                {sender: 'other', content: '你好！'},
                {sender: 'me', content: '哈哈哈哈哈哈哈哈哈哈哈哈哈哈'},
                {sender: 'other', content: '我也很高兴认识你！'},
                {sender: 'other', content: '你好！'},
                {sender: 'me', content: '你好，很高兴认识你！'},
                {sender: 'other', content: '我也很高兴认识你！'},
                {sender: 'other', content: '你好！'},
                {sender: 'me', content: '你好，很高兴认识你！'},
                {sender: 'other', content: '我也很高兴认识你！'},
                {sender: 'other', content: '你好！'},
                {sender: 'me', content: '你好，很高兴认识你！'},
                {sender: 'other', content: '我也很高兴认识你！'},
                {sender: 'other', content: '你好！'},
                {sender: 'me', content: '你好，很高兴认识你！'},
                {sender: 'other', content: '我也很高兴认识你！'},
                // 更多消息...
            ]);

            return {
                messages,
            };
        },
        components: {
            ACard: Card,
            AText: Typography.Text,
        },
    })
</script>

<style scoped>
    .message-row {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 10px;
    }
    
    .message {
        max-width: 80%;
        word-wrap: break-word;
    }
    
    .message-me {
        margin-left: auto;
    }
    
    .message-other {
        margin-right: auto;
    }
</style>