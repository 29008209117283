// 定义公共错误码
export const RESP_CODE = {
    SUCCESS: 0, //成功
    UNKNOWN_ERROR: -1,  //未知错误

    //特殊返回码
    NEED_IDENTITY_SELECT: 1001, //需要身份选择
    NEED_FILL_INFO: 1002, //需要补充资料
    NEED_ADD_EMAIL: 1003, //需要添加邮箱

    //通用错误码
    UPLOAD_FAILED: -1003,  //上传失败
    PARAMS_ERROR: -1002,  //参数错误

    USER_NOT_EXIST: -2001,  //用户不存在
    FILE_NOT_EXIST: -2002,  //文件不存在
    INFO_NOT_EXIST: -2003,  //资料不存在
    EVENT_NOT_EXIST: -2004,  //日程不存在

    NOT_SUPPORT_MODIFY: -3001, //不支持修改
    NOT_SUPPORT_TYPE: -3002, //不支持类型
    NOT_SUPPORT_TIME: -3003, //不支持时间
};

// 登录类型
export const LOGIN_TYPE = {
    GOOGLE: 1,  //谷歌登录
    WECHAT: 2,  //微信登录
}

// 角色
export const USER_ROLE = {
    MUSICIAN: 1, //音乐人
    COMPANY: 2, //主办方
};

// 日程时间
export const EVENT_TIME = {
    // 深夜 0:00 - 6:00
    MIDNIGHT: [0, 21600],
    // 早上 8:00 - 12:00
    MORNING: [28800, 43200],
    // 下午 12:00 - 18:00
    AFTERNOON: [43200, 64800],
    // 晚上 18:00 - 24:00
    EVENING: [64800, 86400],
};

// 日程状态
export const EVENT_STATUS = {
    NONE: 0, //请求预留，表示全部
    MATCHING: 1, //匹配中
    MATCHED: 2, //匹配成功
};

// 匹配类型
export const MATCH_STATUS = {
    // 被动状态
    NONE: 0, //请求预留，表示全部
    AUTOMATCH: 1, //自动匹配
    INVITED: 2, //被邀请
    // 被动 + 主动状态
    INVITING: 3, //邀请他人
    // 主动状态
    IGNORED: 4, //忽略，拒绝
    ACCEPTED: 5, //接受
};

//enterpriseRole
export const ENTERPRISE_ROLE = {
    PERFORMANCE_HOST: "Performance Host", //演出主办方
    CREW: "Crew", //演出工作人员
    MUSIC_FESTIVAL_ORGANIZER: "Music Festival Organizer", //音乐节组织方
    SITE_PROVIDER: "Site Provider", //场地提供方
    NIGHT_CLUB: "Night club", //夜店
    LIVEHOUSE: "Live house", //Livehouse
};

// 音乐风格
export const STYLE_NAME = {
    ROCK: "Rock", //摇滚
    INDIE_POP: "Indie Pop", //Indie Pop 独立流行
    POP: "Pop", //流行
    RNB: "R&B", //R&B
    FOLK: "Folk", //民谣
    NEO_SOUL: "Neo Soul", //新灵魂乐
    URBAN: "Urban", //都市流行
    HIP_HOP: "Hip-Hop", //Hip-Hop
    ACG: "ACG", //ACG
    EDM: "EDM", //EDM
    AVANT_GARDE: "Avant Garde", //前卫
    JAZZ: "Jazz", //爵士
    FUNK: "Funk", //放克
    BOSSA_NOVA: "Bossa Nova", //波萨诺瓦
    SAMBA: "Samba", //森巴
    LATIN: "Latin", //拉丁
    BLUES: "Blues", //蓝调
    LO_FI: "Lo-fi", //Lo-fi
    WORSHIP: "Worship", //Worship
    REGGAE: "Reggae", //Reggae
    ACOUSTIC_BALLADS: "Acoustic Ballads", //Acoustic Ballads
}

export class BaseRequestParams {
    constructor(uid) {
        this.uid = uid;
    }
}

export class BaseResponseParams {
    constructor(code) {
        this.code = code;
        switch (code) {
            case RESP_CODE.SUCCESS:
                this.message = 'succ';
                break;

            case RESP_CODE.NEED_IDENTITY_SELECT:
                this.message = 'need identity select';
                break;
            case RESP_CODE.NEED_FILL_INFO:
                this.message = 'need fill info';
                break;
            case RESP_CODE.NEED_ADD_EMAIL:
                this.message = 'need add email';
                break;

            case RESP_CODE.UNKNOWN_ERROR:
                this.message = 'unknown error';
                break;
            case RESP_CODE.USER_NOT_EXIST:
                this.message = 'user not exist';
                break;
            case RESP_CODE.UPLOAD_FAILED:
                this.message = 'upload failed';
                break;
            case RESP_CODE.PARAMS_ERROR:
                this.message = 'params error';
                break;
            case RESP_CODE.FILE_NOT_EXIST:
                this.message = 'file not exist';
                break;

            case RESP_CODE.NOT_SUPPORT_MODIFY:
                this.message = 'not support modify';
                break;
            case RESP_CODE.NOT_SUPPORT_TYPE:
                this.message = 'not support type';
                break;
            case RESP_CODE.NOT_SUPPORT_TIME:
                this.message = 'not support time';
                break;
            default:
                this.message = '';
        }
    }
}

export const STORAGE_NAME = {
    AVATAR: 'avatar', //头像
    MUSIC: 'music', //音乐
    FILE: 'file', //文件
}