import { BaseRequestParams } from "./commonUtil";

/*
    请求
    * @param {string} uid - 用户uid
    * @param {int} user_role - 角色, 1:音乐人, 2:主办方
*/
export class IdentitySelectRequestParams extends BaseRequestParams {
    constructor(uid, user_role) {
        super(uid);
        this.user_role = user_role;
    }
}