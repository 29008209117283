import { USER_ROLE } from "../functions/commonUtil.js";

export function globalFunction() {
    console.log('This is a global function')
}

export const globalVariable = 'This is a global variable'

var API_HOST = 'https://us-central1-aimusicagent.cloudfunctions.net/api';
// API_HOST = 'http://127.0.0.1:5001/aimusicagent/us-central1/api';

// uid = '71243634769144094721698580617169'; //musician
// uid = '71243628984066416641698580479242';  //company

// let user = {
//   uid: '71273391576590622721699290074745',
//   userRole: USER_ROLE.MUSICIAN,
// }

// let user = {
//   uid: '71273406674197585921699290434699',
//   userRole: USER_ROLE.COMPANY,
// }

let user = {
    uid: null,
    userRole: -1,
    name: null,
    isFirstFill: true,  // 如果表单必填项没有填，不可通过点击Logo来跳转日历
};

/* 获取uid */
let URLParams = new URL(window.location.href).searchParams;
let cookieArr = document.cookie.split(";");
let cookieUid = null;
for(let i = 0; i < cookieArr.length; i++) {
    let cookiePair = cookieArr[i].split("=");
    if ('uid' == cookiePair[0].trim()) {
        cookieUid = cookiePair[1];
    }
}
// 1、URL
if (URLParams.get('uid')) {
    user.uid = URLParams.get('uid');
}
// 2、Cookie
else if (cookieUid) {
    user.uid = cookieUid;
}

export {user, API_HOST}
