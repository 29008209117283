<template>
    <div class="events-bar"
    @click="$emit('hideEventsBar')">
        <div class="events-bar-container"
        @click.stop="">
            <div class="title">Available {{ isMusician ? "hosts" : "Musicians" }}</div>

            <!-- 无匹配结果(matchList的长度为0) -->
            <div v-if="matchList.length == 0">
                <div style="margin-top: 216px; text-align: center;">
                    <img src="../assets/no-match.svg" style="width: 199px; height: 132px;"> 

                    <div style="margin-top: 23px; color: white; font-size: 21px; text-align: center;">
                        No available {{ isMusician ? "host" : "musician" }} found yet.
                    </div>

                    <div style="margin-top: 3px; color: rgba(255, 255, 255, 0.60); font-size: 14px; line-height: 1.5em; text-align: center;">
                        We will keep matching.<br/>
                        We'll update you via email once an available {{ isMusician ? "host" : "musician" }} is found.
                    </div>
                </div>
            </div>

            <!-- 有匹配结果 -->
            <div v-else v-for="(item, index) in matchList" :key="index">
                <div class="host"
                @click="$emit('showEventDetails', item)">
                    <div style="position: relative; display: flex; align-items: center; margin-bottom: 12px;">
                        <div class="match-container-outer">
                            <div class="match-container-inner">
                                <span style="font-size: 16px; font-weight: 500;">{{ Math.round(item.match_score.totalMatch * 100) }}</span>
                                <span style="font-size: 10px; margin-right: 4px;">%</span>
                                <span style="font-size: 14px; font-weight: 400;">Match</span>
                            </div>
                        </div>
                        <div class="host-name">{{ item.creator_info.name }}</div>
                        <img v-if="isMusician && item.creator_info.cert_file" src="../assets/ic_outline-shield.svg" style="width: 20px; height: 20px;">
                        <div class="cost"><span>{{ getCostText(item) }}</span></div>
                    </div>

                    <div style="display: flex; margin-bottom: 8px; align-items: center;">
                        <img src="../assets/location-16.svg" style="width: 16px; height: 16px;" />
                        <span style="margin-left: 8px; font-size: 12px;">{{ item.event_info.event_location ? item.event_info.event_location[0] : "Unlimited" }}</span>
                    </div>

                    <div style="display: flex; margin-bottom: 12px; align-items: center;">
                        <img src="../assets/access-time-16.svg" style="width: 16px; height: 16px;" />
                        <span style="margin-left: 8px; font-size: 12px;">{{ getTimeText(item) }}</span>
                    </div>

                    <div style="display: flex; justify-content: left; position: relative;">
                        <div class="tags-container">
                            <div v-for="(tag, index) in item.event_info.event_music_style" :key="index" class="tag">{{ tag }}</div>
                        </div>

                        <div style="width: 0; flex: 1;"></div>

                        <div style="display: flex; flex-direction: column; justify-content: flex-end;">
                            <div style="height: 0; flex: 1"></div>

                            <!-- 提示语 -->
                            <div class="has-invited-caption" v-if="item.match_status == MATCH_STATUS.INVITED">{{ isMusician ? "Host" : "Musician" }} has invited</div>

                            <div>
                                <!-- 双方都未请求 -->
                                <button class="ignore-decline-button" v-if="item.match_status == MATCH_STATUS.AUTOMATCH"
                                @click.stop="ignoreDeclineHandler(index)"><span>Ignore</span></button>
                                <button class="invite-accept-button" v-if="item.match_status == MATCH_STATUS.AUTOMATCH"
                                @click.stop="inviteHandler(index)"><span>Invite</span></button>
                                <!-- 己方已请求 -->
                                <button class="invited-button" v-if="item.match_status == MATCH_STATUS.INVITING"><span>Invited</span></button>
                                <!-- 对方已请求 -->
                                <button class="ignore-decline-button" v-if="item.match_status == MATCH_STATUS.INVITED"
                                @click.stop="ignoreDeclineHandler(index)"><span>Decline</span></button>
                                <button class="invite-accept-button" v-if="item.match_status == MATCH_STATUS.INVITED"
                                @click.stop="acceptHandler(index)"><span>Accept</span></button>
                            </div>
                        </div>
                    </div>
                    <!-- 新消息 -->
                    <div class="new" v-if="item.red_dot">New</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { USER_ROLE, MATCH_STATUS } from "../functions/commonUtil.js";
import { user, API_HOST } from "../js/global.js";
import { UpdateMatchStatusRequestParams } from "../functions/updateMatchStatusParams.js";
import dayjs from "dayjs";

export default {
    props: ["eventId", "matchList"],
    data() {
        return {
            MATCH_STATUS: MATCH_STATUS,
        }
    },
    computed: {
        isMusician() {  // true-音乐人界面，false-主办方界面
            if (user.userRole == USER_ROLE.MUSICIAN) {
                return true;
            } 
            else {
                return false;
            }
        },
    },
    methods: {
        getTimeText(item) {
            let startDate = dayjs(item.event_info.event_start_date * 1000).format('D/MM/YYYY');
            let endDate = dayjs(item.event_info.event_end_date * 1000).format('D/MM/YYYY');
            let timeTxt = "";
            if (item.event_info.event_times) {
                let times = [];
                for(let j = 0; j < item.event_info.event_times.length; j++) {
                    let timeItem = item.event_info.event_times[j];
                    let startTime = new Date(timeItem[0] * 1000);
                    let startHour = startTime.getUTCHours();
                    let startMinutes = startTime.getUTCMinutes();
                    if (startMinutes < 10) {
                        startMinutes = "0" + startMinutes;
                    }
                    let endTime = new Date(timeItem[1] * 1000);
                    let endHour = endTime.getUTCHours();
                    if (endHour == 0) {  // 如果结束时间的UTCHour为0，将其转化为24
                        endHour = 24;
                    }
                    let endMinutes = endTime.getUTCMinutes();
                    if (endMinutes < 10) {
                        endMinutes = "0" + endMinutes;
                    }
                    times.push(startHour + ":" + startMinutes + " - " + endHour + ":" + endMinutes);
                }
                timeTxt = times.join(",");
            }
            else {
                timeTxt = "Unlimited";
            }
            if (startDate == endDate) {
                timeTxt += ", " + startDate;
            }
            else {
                timeTxt += ", " + startDate + " - " + endDate;
            }
            return timeTxt;
        },

        getCostText(item) {
            if(this.isMusician) {
                return "$ " + item.event_info.event_reward_lower + " - " + item.event_info.event_reward_upper;
            }
            else {
                return "$ " + item.event_info.event_reward_lower;
            }
        },

        ignoreDeclineHandler(index) {
            // 忽略，拒绝
            const updateMatchStatusReq = new UpdateMatchStatusRequestParams(user.uid, this.eventId, this.matchList[index].event_info.event_id,
                                                                            MATCH_STATUS.IGNORED);
            console.log(updateMatchStatusReq);
            const cgiUrl = API_HOST + '/updateMatchStatus';
            const body = JSON.stringify(updateMatchStatusReq);
            fetch(cgiUrl, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log("结果：", response);
                if (!response.ok) {
                    console.log(cgiUrl, '失败，错误码：' + response.status);
                    return;
                }
                return response.json(); // 解析JSON响应;
            })
            .then((responseData) => {
                console.log('数据:', responseData);
                this.$emit('changeMatchListStatus', index, MATCH_STATUS.IGNORED);
                this.$emit('ignoreMatch', index);
            })
            .catch((error) => {
                console.log(cgiUrl, "失败：", error);
            });
        },

        inviteHandler(index) {
            // 发送邀请
            const updateMatchStatusReq = new UpdateMatchStatusRequestParams(user.uid, this.eventId, this.matchList[index].event_info.event_id,
                                                                            MATCH_STATUS.INVITING);
            console.log(updateMatchStatusReq);
            const cgiUrl = API_HOST + '/updateMatchStatus';
            const body = JSON.stringify(updateMatchStatusReq);
            fetch(cgiUrl, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log("结果：", response);
                if (!response.ok) {
                    console.log(cgiUrl, '失败，错误码：' + response.status);
                    return;
                }
                return response.json(); // 解析JSON响应;
            })
            .then((responseData) => {
                console.log('数据:', responseData);
                this.$emit('changeMatchListStatus', index, MATCH_STATUS.INVITING);
            })
            .catch((error) => {
                console.log(cgiUrl, "失败：", error);
            });
        },

        acceptHandler(index) {
            // 接受邀请
            const updateMatchStatusReq = new UpdateMatchStatusRequestParams(user.uid, this.eventId, this.matchList[index].event_info.event_id,
                                                                            MATCH_STATUS.ACCEPTED);
            console.log(updateMatchStatusReq);
            const cgiUrl = API_HOST + '/updateMatchStatus';
            const body = JSON.stringify(updateMatchStatusReq);
            fetch(cgiUrl, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log("结果：", response);
                if (!response.ok) {
                    console.log(cgiUrl, '失败，错误码：' + response.status);
                    return;
                }
                return response.json(); // 解析JSON响应;
            })
            .then((responseData) => {
                console.log('数据:', responseData);
                this.$emit('changeMatchListStatus', index, MATCH_STATUS.ACCEPTED);
                this.$emit('acceptMatch', index);
            })
            .catch((error) => {
                console.log(cgiUrl, "失败：", error);
            });
        },
    }
}
</script>

<style scoped>
.events-bar {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-left: 57.5%;
    z-index: 10;
    background: rgba(0,0,0,0.4);
    backdrop-filter: blur(10px);
    font-family: PingFang SC;
    word-wrap: break-word;
    font-weight: 400;
}

.events-bar-container {
    color: white;
    height: 100%;
    border-radius: 8px;
    padding: 35px 40px;
    background: #1e1e1e;
    overflow: auto;
}

.events-bar-container-container::-webkit-scrollbar {
    width: 0;
}

.title {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 27px
}

.host {
    position: relative;
    padding: 20px;
    background: #272727;
    margin-bottom: 16px;
    border: 2px solid;
    border-radius: 4px;
    border-color: rgba(207, 128, 235, 0);
}

.host:hover {
    border-color: rgba(207, 128, 235, 1);
}

.match-container-outer {
    display: inline-block;
    padding: 2px;
    border-radius: 4px;
    background: linear-gradient(to right, #c486e4, #7b54f4);
}

.match-container-inner {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 6px 8px;
    background-color: #1e1e1e;
    border-radius: 4px;
}

.host-name {
    display: inline-block;
    font-size: 16px;
    margin-left: 8px;
    margin-right: 5px;
}

.cost {
    position: absolute;
    right: 0;
    color: #CF80EB;
    font-size: 21px;
    font-weight: 500;
}

.ignore-decline-button {
    width: 76px;
    padding: 1.5px 0px;
    margin-right: 12px;
    border: 1.5px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #1e1e1e, #1e1e1e), linear-gradient(to right, #c486e4, #7b54f4);
    border-radius: 4px;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
}

.ignore-decline-button span {
    display: inline-block;
    background-image: linear-gradient(to right, #c486e4, #7b54f4);
    color: transparent;
    background-clip: text;
}

.invite-accept-button {
    width: 76px;
    padding: 4px 0;
    border: 0px;
    border-radius: 4px;
    background: linear-gradient(to right, #be82e6, #7f56f3);
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
}

.invited-button {
    width: 76px;
    padding: 4px 0;
    border: 0px;
    border-radius: 4px;
    background: linear-gradient(to right, #be82e6, #7f56f3);
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 22px;
    opacity: 0.40
}

.tags-container {
    max-width: 60%;
}

.tag {
    display: inline-block;
    padding: 1px 8px;
    margin: 0 4px 8px 4px;
    border: 1px solid;
    border-radius: 2px;
    border-color: #CF80EB;
    color: #CF80EB;
    font-size: 12px;
    font-family: Roboto;
    line-height: 20px;
}

.new {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0px 4px;
    border-radius: 0 4px 0 4px;
    background-color: rgba(248, 59, 99, 1);
    color: white;
    font-size: 10px;
}

.has-invited-caption {
    margin-bottom: 4px;
    color: #FAEA90;
    font-size: 14px;
    font-family: Roboto;
    text-align: right;
}
</style>