<template>
    <div class="email-auth-background-div"></div>
    <div class="email-auth-container">
        <div class="email-auth-name">
            Hi, <img src="../assets/avatar.png" class="avatar"> Jay Chou
        </div>
        <div class="email-auth-hint">Please leave your Google Email to receive important notifications</div>
        <div class="email-auth-gmail">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group 30">
                    <circle id="Ellipse 8" cx="10" cy="10.1562" r="10" fill="white"/>
                    <g id="Group 25">
                        <path id="Vector"
                              d="M16.5372 8.85075H16.0002V8.82308H10.0002V11.4897H13.7678C13.2182 13.0421 11.7412 14.1564 10.0002 14.1564C7.79116 14.1564 6.00016 12.3654 6.00016 10.1564C6.00016 7.94741 7.79116 6.15641 10.0002 6.15641C11.0198 6.15641 11.9475 6.54108 12.6538 7.16941L14.5395 5.28375C13.3488 4.17408 11.7562 3.48975 10.0002 3.48975C6.3185 3.48975 3.3335 6.47475 3.3335 10.1564C3.3335 13.8381 6.3185 16.8231 10.0002 16.8231C13.6818 16.8231 16.6668 13.8381 16.6668 10.1564C16.6668 9.70941 16.6208 9.27308 16.5372 8.85075Z"
                              fill="#FFC107"/>
                        <path id="Vector_2"
                              d="M4.10205 7.05341L6.29238 8.65975C6.88505 7.19241 8.32038 6.15641 10.0001 6.15641C11.0197 6.15641 11.9474 6.54108 12.6537 7.16941L14.5394 5.28375C13.3487 4.17408 11.7561 3.48975 10.0001 3.48975C7.43938 3.48975 5.21872 4.93541 4.10205 7.05341Z"
                              fill="#FF3D00"/>
                        <path id="Vector_3"
                              d="M9.99945 16.822C11.7215 16.822 13.2861 16.163 14.4691 15.0914L12.4058 13.3454C11.714 13.8715 10.8686 14.1561 9.99945 14.1554C8.26545 14.1554 6.79312 13.0497 6.23845 11.5067L4.06445 13.1817C5.16779 15.3407 7.40845 16.822 9.99945 16.822Z"
                              fill="#4CAF50"/>
                        <path id="Vector_4"
                              d="M16.537 8.84944H16V8.82178H10V11.4884H13.7677C13.5047 12.2272 13.0311 12.8728 12.4053 13.3454L12.4063 13.3448L14.4697 15.0908C14.3237 15.2234 16.6667 13.4884 16.6667 10.1551C16.6667 9.70811 16.6207 9.27178 16.537 8.84944Z"
                              fill="#1976D2"/>
                    </g>
                </g>
            </svg>
            
            <div class="email-auth-gmail-text">Grant Email</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EmailAuthorizationPage"
    }
</script>

<style scoped>
    .email-auth-gmail-text {
        padding-left: 8px;
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .email-auth-gmail {
        display: inline-flex;
        padding: 10px 45px 10px 44px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: linear-gradient(270deg, #8549FD 0%, #9E5BF7 32.29%, #CF80EB 100%);
        text-align: center;
        margin: 0 auto;
        
    }
    
    .email-auth-hint {
        color: #FFF;
        font-family: PingFang SC;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-bottom: 48px;
    }
    
    .email-auth-name {
        width: auto;
        text-align: center;
        width: auto;
        padding-top: 297px;
        padding-bottom: 26px;
        font-family: PingFang SC;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: linear-gradient(270deg, #8549FD 0%, #9E5BF7 32.29%, #CF80EB 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .email-auth-background-div {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-color: black;
    }
    
    .email-auth-container {
        display: grid;
        justify-content: center;
        grid-column-start: 1;
    }

    .avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-left: 12px;
        margin-right: 12px;
        overflow: hidden;
    }

</style>