import { BaseRequestParams, BaseResponseParams } from "./commonUtil";

/*
    请求
    * @param {string} uid - 用户uid
    * @param {int} query_type - 查询类型, 1:核心信息, 2:全部信息
*/

export const QUERY_USER_INFO_TYPE = {
    CORE_INFO: 1, //核心信息
    ALL_INFO: 2, //全部信息
}

export class QueryUserInfoRequestParams extends BaseRequestParams {
    constructor(uid, query_type) {
        super(uid);
        this.query_type = query_type;
    }
}

/*
    响应
    * @param {int} code - 响应码, RESP_CODE
    * @param {string} message - 响应消息
*/
export class QueryUserInfoResponseParams extends BaseResponseParams {
    constructor(code) {
        super(code);

        // 核心
        this.user_role = null; //角色
        this.name = null; //姓名
        this.email = null; //邮箱
        this.avatar = null; //头像
        
        // 通用
        this.info_file = null; //信息文件

        // 主办方
        this.cert_file = null; //证书文件
        this.enterprise_name = null; //企业名
        this.contact = null; //联系电话
        this.bussiness_area = null; //企业领域
        this.address = null; //企业注册地址
        this.founding_time = null; //创办时间

        this.enterprise_role = null; //企业角色
        this.founder_info = null; //创始人信息
        this.event_hosting = null; //举办过的活动

        // 音乐人
        this.musician_files = null; //音乐文件
        this.occupation_time = null; //从业时长
        this.music_background = null; //音乐背景
        this.performance_experience = null; //演出经历
        this.music_style = null; //音乐风格
    }
}