import { BaseRequestParams, BaseResponseParams } from "./commonUtil";

/*
    请求
    * @param {string} uid - 用户uid
    * @param {string} event_id, 日程id
*/

export class QueryMatchsRequestParams extends BaseRequestParams {
    constructor(uid, event_id) {
        super(uid);
        this.event_id = event_id;
    }
}

/*
    响应
    * @param {int} code - 响应码, RESP_CODE
    * @param {string} message - 响应消息
    * @param {list} match_list - 匹配列表，对方已请求＞双方都未请求＞己方已请求；更新时间由远到近排序
*/
export class QueryMatchsResponseParams extends BaseResponseParams {
    constructor(code, match_list) {
        super(code);
        this.match_list = match_list;
    }
}