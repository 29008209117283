<template>
<div class="event-post">
    <div class="event-post-container" style="background: #262626;">

        <!-- 日程发布中 -->
        <div v-show="postingEvent">
            <div style="display: flex; justify-content: center;">
                <img src="../assets/loading.gif" style="width: 100%;">
            </div>

            <div style="margin-top: 56px; color: white; font-size: 21px; text-align: center;">
                Event has been posted
            </div>

            <div style="margin-top: 12px; display: flex; justify-content: center; align-items: center;">
                <span style="margin-right: 12px; color: rgba(255, 255, 255, 0.60); font-size: 16px;">Initializing AI Match</span>
                <span style="color: #CF80EB; font-size: 16px;">{{ postProgress }}%</span>
            </div>
        </div>

        <!-- 发布成功-有/无匹配结果(根据matchList的长度动态显示) -->
        <div v-show="!postingEvent">
            <img class="close-button" src="../assets/Close.svg" 
            @click="$emit('closeEventPost')"/>

            <div style="margin-top: 117px; display: flex; justify-content: center;">
                <img src="../assets/post-successful.svg" style="width: 160px; height: 160px;">
            </div>

            <div style="margin-top: 40px; color: white; font-size: 21px; text-align: center;">
                {{ getMatchResText(matchList) }}
            </div>

            <div style="margin-top: 12px; color: rgba(255, 255, 255, 0.60); font-size: 14px; line-height: 1.5em; text-align: center;">
                We will keep matching.<br>
                We'll update you via email once an available musician is found.
            </div>

            <div style="margin-top: 40px; text-align: center;">
                <button style="padding: 11px 16px; border-radius: 4px; border: 0; background: linear-gradient(270deg, #8549FD 0%, #9E5BF7 32%, #CF80EB 100%); font-size: 16px; color: white; font-family: Roboto; font-weight: 500; cursor: pointer;"
                @click="$emit('showEventsBar')">
                   {{ getCheckButtonText(matchList) }} </button>
            </div>
        </div>
        
    </div>
</div>
</template>

<script>
import { List } from 'ant-design-vue'

import { user } from "../js/global.js";
import { USER_ROLE } from "../functions/commonUtil.js";

export default {
    props: {
        'postingEvent': Boolean,
        'matchList': List,
        'postProgress': Number,
    },
    data() {
        return {
        }
    },
    computed: {
        isMusician() {  // true-音乐人界面，false-主办方界面
            if (user.userRole == USER_ROLE.MUSICIAN) {
                return true;
            } 
            else {
                return false;
            }
        },
    },
    methods: {
        getMatchResText(matchRes) {
            if (matchRes.length == 0) {
                return "Event has been posted"
            }

            var targetName = "musician"
            var verb = "is"
            if (this.isMusician) {
                targetName = "host"
            }

            if (matchRes.length > 1) {
                targetName += 's'
                verb = "are"
            }

            return "Congrats! " + matchRes.length + " " + targetName + " " + verb + " found."
        },

        getCheckButtonText(matchRes) {
            if (matchRes.length == 0) {
                return "Check Matching Status"
            }

            var targetName = "Musician"
            if (this.isMusician) {
                targetName = "Host"
            }

            if (matchRes.length > 1) {
                targetName += "s";
            }

            return "See Available " + targetName
        }
    }
}
</script>

<style scoped>
.event-post {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0,0,0,0.4);
    backdrop-filter: blur(10px);
    font-family: PingFang SC;
    word-wrap: break-word;
    font-weight: 400;
}

.event-post-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 597px;
    background: radial-gradient(53.01% 78.10% at 48.64% 91.46%, #2A185B 0%, #291E3E 100%);
    border-radius: 4px;
    border: 2px rgba(255, 255, 255, 0.10) solid
}

.close-button {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 24px;
    width: 14px;
    height: 14px;
    cursor: pointer;
}
</style>