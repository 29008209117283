<template>
<div class="ai-dialogue">
    <div class="ai-dialogue-container">
        <img class="close-button" src="../assets/Close.png" 
        @click="$emit('closeAiDialogue')"/>

        <!-- <div style="display: inline-flex; align-items: center; margin-bottom: 40px; cursor: pointer;"
        @click="$emit('backToEdit')">
            <img src="../assets/ai-dialogue-ltr.svg" style="width: 12px; height: 12px; margin-right: 4px;">
            <span style="color: #CF80EB; font-size: 16px; font-family: Roboto;">Back to Edit</span>
        </div> -->

        <div class="dialogue-container">
            <div style="display: flex; justify-content: center;">
                <img src="../assets/ai-dialogue.svg" style="width: 160px; height: 112px;">
            </div>

            <div style="padding: 20px; margin-top: 24px; border-radius: 8px; background: rgba(255, 255, 255, 0.10)">
                <div style="margin-bottom: 12px; text-align: center; color: #CF80EB; font-size: 21px;">Hi, {{ userName }}. I'm your AI assistant.</div>
                <div style="text-align: center; color: white; font-size: 14px; line-height: 1.5em;">I have received your arrangement. Please tell me about your preferences :) Would you like to bring your own gears, or  host to prepare them?</div>
            </div>

            <div style="margin-top: 24px; text-align: center; color: #CF80EB; font-size: 14px; font-family: Roboto; line-height: 22px;">
                <span style="cursor: pointer;"
                @click="$emit('editEvent')">Skip and Post Event</span>
            </div>

            <div style="margin-top: 24px; padding: 16px; border-radius: 8px 8px 2px 8px; background: #8549FD; color: white; font-size: 14px; line-height: 1.5em;"
            v-show="showUserMsg">
                {{ userMsg }}
            </div>

            <!-- <div style="margin-top: 24px; padding: 16px; border-radius: 2px 8px 8px 8px; background: rgba(255, 255, 255, 0.10); color: white; font-size: 14px; line-height: 1.5em;">
                ...
            </div> -->

            <div style="margin-top: 24px; padding: 16px; border-radius: 2px 8px 8px 8px; background: rgba(255, 255, 255, 0.10); color: white; font-size: 14px; line-height: 1.5em; white-space: pre-line;"
            v-show="showAiMsg"
            ref="back">
                {{ aiMsg == null ? "..." : aiMsg }}

                <div style="display: flex; justify-content: center; margin-top: 12px;"
                v-show="aiMsg != null">
                    <button style="width: 200px; border-radius: 4px; padding: 11px 16px; color: white; font-size: 16px; font-family: Roboto; font-weight: 500; border: 0; background: linear-gradient(270deg, #8549FD 0%, #9E5BF7 32%, #CF80EB 100%); cursor: pointer;"
                    @click="$emit('editEvent')">Next</button>
                </div>
            </div>
        </div>
        
        <div style="margin-top: 24px; text-align: center;">
            <input class="message-input" placeholder="Example" 
            v-model="userInput"
            @keyup.enter="sendMsg"/>
            
            <div class="message-send-button"
            @click="sendMsg">
                <img src="../assets/message-send.svg" style="width: 20px; height: 20px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);">
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { user, API_HOST } from "../js/global.js"
import { USER_ROLE } from "../functions/commonUtil.js";
import { UpdateEventRequestParams } from "../functions/updateEventParams.js";

export default {
    data() {
        return {
            userName: user.name,
            userMsg: null,
            aiMsg: null,
            userInput: null,
            showUserMsg: false,  // 用户信息的显示与隐藏
            showAiMsg: false,  // AI信息的显示与隐藏
            // I'll bring my own guita and some effectors. The host should prepare the others. Btw, my IEM is down. Please make sure the host has them to deliver a good performence.
        }
    },
    methods: {
        dialogueScrollToBottom() {
            this.$refs.back.scrollIntoView();
        },
        sendMsg() {
            if (this.userInput == null) {
                return;
            }
            
            this.showUserMsg = true;
            this.userMsg = this.userInput;
            this.userInput = null;

            const updateEventReq = new UpdateEventRequestParams(user.uid);
            updateEventReq.event_description = [this.userMsg];
            const cgiUrl = API_HOST + '/updateEvent';
            const body = JSON.stringify(updateEventReq);
            fetch(cgiUrl, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log("结果：", response);
                if (!response.ok) {
                    console.log(cgiUrl, '失败，错误码：' + response.status);
                    return;
                }
                return response.json(); // 解析JSON响应;
            })
            .then((responseData) => {
                console.log('数据:', responseData);
                this.aiMsg = null;
                this.showAiMsg = true;
                setTimeout(() => {
                    this.dialogueScrollToBottom();
                    this.getAiMsg();
                }, 400);                
            })
            .catch((error) => {
                console.log(cgiUrl, "失败：", error);
            });
        },

        getAiMsg() {
            setTimeout(() => {
                this.aiMsg = this.userName + "! I'll send your request to the " + (this.isMusician ? "host" : "musician") + ", and make sure that he/she has effectors and IEMs.\nFeel free to tell me if you have more requests, or hit the button below to start matching hosts."
                setTimeout(this.dialogueScrollToBottom, 400);
            }, 500);
        }
    },
    computed: {
        isMusician() {  // true-音乐人界面，false-主办方界面
            if (user.userRole == USER_ROLE.MUSICIAN) {
                return true;
            }
            return false;
        },
    }
}
</script>

<style scoped>
.ai-dialogue {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0,0,0,0.4);
    backdrop-filter: blur(10px);
    font-family: PingFang SC;
    word-wrap: break-word;
}

.ai-dialogue-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 597px;
    padding: 24px;
    background: radial-gradient(53.01% 78.10% at 48.64% 91.46%, #2A185B 0%, #291E3E 100%);
    border-radius: 4px;
    border: 2px rgba(255, 255, 255, 0.10) solid
}

.close-button {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 24px;
    width: 14px;
    height: 14px;
    cursor: pointer;
}

.dialogue-container {
    margin-top: 55px;
    height: 420px;
    overflow: auto;
}

.dialogue-container::-webkit-scrollbar {
    width: 0;
}

.message-input {
    display: inline-block;
    width: 90%;
    padding: 8px 12px;
    height: 40px;
    border: 0;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.10);
    vertical-align: middle;
    color: white;
    font-size: 16px;
    line-height: 24px;
    outline: none;
}

.message-send-button {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-left: 8px;
    vertical-align: middle;
    border: 1.5px solid transparent;
    border-radius: 4px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #251c36, #251c36), linear-gradient(to right, #c486e4, #7b54f4);
    align-items: middle;
    cursor: pointer;
}
</style>