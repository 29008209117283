<template>
    <div class="event-details">
        <div class="event-details-container">
            <img class="close-button" src="../assets/Close.svg" 
            @click="$emit('closeEventDetails')"/>
            <div class="title">
                <span class="obj-name">
                    {{ eventDetails.creator_info.name }}
                    <img v-if="isMusician && eventDetails.creator_info.cert_file" src="../assets/ic_outline-shield.svg" style="width: 20px; height: 20px; margin-left: 4px;">
                </span>
                <span class="obj-type"
                v-for="(role, index) in eventDetails.creator_info.enterprise_role" :key="index">{{ role }}</span>
                <span class="obj-match">{{ Math.round(eventDetails.match_score.totalMatch * 100) + "% Match" }}</span>
            </div>

            <div class="details-container">
                <div class="match-details">
                    <div class="match-details-line">
                        <div class="match-details-left show-name"> {{ eventDetails.event_info.event_name }} </div>
                        <div class="match-details-right match-rate-header">Match Rate</div>
                    </div>
                    <div class="match-details-line">
                        <div class="match-details-left match-key">
                            <img src="../assets/location-16.svg" style="width: 16px; height: 16px;"/>
                            <span>{{ eventDetails.event_info.event_location ? eventDetails.event_info.event_location[0] : "Unlimited" }}</span>
                        </div>
                        <div class="match-details-right">
                            <ProgressBar :percentage="Math.round(eventDetails.match_score.cityMatch * 100)"></ProgressBar>
                        </div>
                    </div>
                    <div class="match-details-line">
                        <div class="match-details-left match-key">
                            <img src="../assets/access-time-16.svg" style="width: 16px; height: 16px;"/>
                            <span>{{ getTimeText() }}</span>
                        </div>
                        <div class="match-details-right">
                            <ProgressBar :percentage="Math.round(eventDetails.match_score.dateTimeMatch * 100)"></ProgressBar>
                        </div>
                    </div>
                    <div class="match-details-line">
                        <div class="match-details-left match-key">
                            <img src="../assets/music-16.svg" style="width: 16px; height: 16px;"/>
                            <div>
                                <div class="tag" v-for="(tag, index) in eventDetails.event_info.event_music_style" :key="index">{{ tag }}</div>
                            </div>
                        </div>
                        <div class="match-details-right">
                            <ProgressBar :percentage="Math.round(eventDetails.match_score.styleMatch * 100)"></ProgressBar>
                        </div>
                    </div>
                    <div class="match-details-line">
                        <div class="match-details-left match-key">
                            <img src="../assets/dollar-16.svg" style="width: 16px; height: 16px;"/>
                            <span class="cost">{{ getCostText() }}</span>
                        </div>
                        <div class="match-details-right">
                            <ProgressBar :percentage="Math.round(eventDetails.match_score.rewardMatch * 100)"></ProgressBar>
                        </div>
                    </div>
                </div>
                <!-- 请求栏 -->
                <div class="requests-container" v-if="eventDetails.event_info.event_description && eventDetails.event_info.event_description.length > 0">
                    <div class="ai-summary">
                        <img src="../assets/ai-summary.svg" style="width: 16px; height: 16px;"/>
                        <span>AI Summary</span>
                    </div>
                    <div class="requests-headline">{{ getRequestHeadlineText() }}</div>
                    <div v-for="(item, index) in eventDetails.event_info.event_description" :key="index"
                        class="request-item" :style="firstRequestStyle(index)">
                        <div class="request-outline">{{ item }}</div>
                        <div class="request-content">{{ item }}</div>
                    </div>
                </div>
            </div>

            <div class="invite-button-container">
                <div class="get-invitation" v-if="isInvited">{{ invitationText }}</div>
                <button v-if="isAutoMatch || isInvited" style="cursor: pointer;"
                @click="inviteAcceptHandler">{{ inviteAcceptBtnText }}</button>
                
                <button v-if="isInviting || isAccepted" style="opacity: 0.40;">{{ isInviting ? "Invited" : "Accepted" }}</button>
            </div>
            
        </div>
        <div class="send-invitation-alert" v-show="showAlert">
            <img src="../assets/check-circle.svg" style="width: 16px; height: 16px;">
            <span>{{ alertText }}</span>
        </div>
    </div>
</template>


<script>
import ProgressBar from "./progressBar";
import { USER_ROLE, MATCH_STATUS } from "../functions/commonUtil.js";
import { UpdateMatchStatusRequestParams } from "../functions/updateMatchStatusParams.js";
import { user, API_HOST } from "../js/global.js";
import dayjs from "dayjs";

export default {
    props: ["eventDetails", "currentDate", "eventId"],
    components: {
        ProgressBar: ProgressBar
    },
    data() {
        return {
            showAlert: false,  // 控制send alert显示
            alertText: "",  // alert显示的文字
            eventObject: {  // 音乐人界面
                objName: "Walner Music",
                objType: "Music Festival Host",
                match: 93,
                showName: "Strawberry Music Festival",
                location: "New York City",
                locationMatch: 90,
                time: "13:00 - 14:00, 9/01/2023",
                timeMatch: 87,
                tags: ["Jazz", "R&B", "Blues", "Rock&roll"],
                tagsMatch: 65,
                minCost: 5000,
                maxCost: 8000,
                costMatch: 87,
                // requests: [],
                requests: [
                    {
                        outline: "Arrive 1 hour ahead",
                        content: "The host expect you to arrive 1 hour ahead to prepare makeup"
                    },
                    {
                        outline: "Instruments prepared",
                        content: "You don’t need to bring your own instruments, as they are prepared by the host."
                    },
                    {
                        outline: "Instruments prepared",
                        content: "You don’t need to bring your own instruments, as they are prepared by the host."
                    }
                ],
                getInvitation: false
            },
            // eventObject: {  // 主办方界面
            //     objName: "JJ Lin",
            //     objType: null,
            //     match: 93,
            //     showName: "Guitar Performance",
            //     location: "New York City",
            //     locationMatch: 90,
            //     time: "13:00 - 14:00, 9/01/2023",
            //     timeMatch: 87,
            //     tags: ["Jazz", "R&B", "Blues", "Rock&roll"],
            //     tagsMatch: 65,
            //     minCost: 5000,
            //     maxCost: 8000,
            //     costMatch: 87,
            //     // requests: [],
            //     requests: [
            //         {
            //             outline: "IEM needed",
            //             content: "The musician’s IEM is broken. He’ll apprieciate it if you can provide that."
            //         }
            //     ],
            //     getInvitation: false
            // }
        }
    },
    computed: {
        isMusician() {  // true-音乐人界面，false-主办方界面
            if (user.userRole == USER_ROLE.MUSICIAN) {
                return true;
            }
            return false;
        },  

        isAutoMatch() {  // 是否双方匹配，但都没发出邀请
            if (this.eventDetails.match_status == MATCH_STATUS.AUTOMATCH) {
                return true;
            }
            return false;
        },

        isInviting() {  // 是否已发出邀请
            if (this.eventDetails.match_status == MATCH_STATUS.INVITING) {
                return true;
            }
            return false;
        },

        isInvited() {  // 是否已收到邀请
            if (this.eventDetails.match_status == MATCH_STATUS.INVITED) {
                return true;
            }
            return false;
        },

        isAccepted() {  // 是否已接受邀请
            if (this.eventDetails.match_status == MATCH_STATUS.ACCEPTED) {
                return true;
            }
            return false;
        },

        invitationText() {
            if (this.isMusician) {
                return "Host has invited";
            }
            else {
                return "Musician has invited";
            }
        },

        inviteAcceptBtnText() {  // 显示Invite还是Accept
            if (this.isInvited) {
                return "Accept"; 
            }
            return "Invite";
        },

    },
    methods: {
        getRequestHeadlineText() {
            if(this.isMusician) {
                return "Requests from Host"
            }
            else {
                return "Requests from Musician"
            }
        },
        firstRequestStyle(index) {
            if(index == 0){
                return {
                    marginTop: '24px'
                }
            }
            else {
                return {}
            }
        },

        inviteAcceptHandler() {
            let newStatus = null;
            if (this.isInvited) {  // 接受邀请
                newStatus = MATCH_STATUS.ACCEPTED; 
                this.alertText = "Acceptance sent";
            }
            else {  // 发送邀请
                newStatus = MATCH_STATUS.INVITING;
                this.alertText = "Invitation sent";
            }

            const updateMatchStatusReq = new UpdateMatchStatusRequestParams(user.uid, this.eventId, this.eventDetails.event_info.event_id,
                                                                            newStatus);
            console.log(updateMatchStatusReq);
            const cgiUrl = API_HOST + '/updateMatchStatus';
            const body = JSON.stringify(updateMatchStatusReq);
            fetch(cgiUrl, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log("结果：", response);
                if (!response.ok) {
                    console.log(cgiUrl, '失败，错误码：' + response.status);
                    return;
                }
                return response.json(); // 解析JSON响应;
            })
            .then((responseData) => {
                console.log('数据:', responseData);
                this.showAlert = true;
                this.$emit('changeMatchStatus', newStatus);
                setTimeout(() => {
                    this.showAlert = false;
                }, 1000);
            })
            .catch((error) => {
                console.log(cgiUrl, "失败：", error);
            });
        },

        getRoleText() {
            if (this.eventDetails.creator_info.user_role == USER_ROLE.MUSICIAN) {
                return "Musician";
            }
            else if (this.eventDetails.creator_info.user_role == USER_ROLE.COMPANY) {
                return "Music Festival Host";
            }
        },

        getTimeText() {
            let startDate = dayjs(this.eventDetails.event_info.event_start_date * 1000).format('D/MM/YYYY');
            let endDate = dayjs(this.eventDetails.event_info.event_end_date * 1000).format('D/MM/YYYY');
            let timeTxt = "";
            if (this.eventDetails.event_info.event_times) {
                let times = [];
                for(let j = 0; j < this.eventDetails.event_info.event_times.length; j++) {
                    let timeItem = this.eventDetails.event_info.event_times[j];
                    let startTime = new Date(timeItem[0] * 1000);
                    let startHour = startTime.getUTCHours();
                    let startMinutes = startTime.getUTCMinutes();
                    if (startMinutes < 10) {
                        startMinutes = "0" + startMinutes;
                    }
                    let endTime = new Date(timeItem[1] * 1000);
                    let endHour = endTime.getUTCHours();
                    if (endHour == 0) {  // 如果结束时间的UTCHour为0，将其转化为24
                        endHour = 24;
                    }
                    let endMinutes = endTime.getUTCMinutes();
                    if (endMinutes < 10) {
                        endMinutes = "0" + endMinutes;
                    }
                    times.push(startHour + ":" + startMinutes + " - " + endHour + ":" + endMinutes);
                }
                timeTxt = times.join(",");
            }
            else {
                timeTxt = "Unlimited";
            }
            if (startDate == endDate) {
                timeTxt += ", " + startDate;
            }
            else {
                timeTxt += ", " + startDate + " - " + endDate;
            }
            return timeTxt;
        },

        getCostText() {
            const reward_lower = this.eventDetails.event_info.event_reward_lower;
            const reward_upper = this.eventDetails.event_info.event_reward_upper;
            if (reward_lower == reward_upper) {
                return "$ " + reward_lower;
            }
            else {
                return "$ " + reward_lower + " - " + reward_upper;
            }
        }
    },
}
</script>

<style scoped>
.event-details {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0,0,0,0.4);
    backdrop-filter: blur(10px);
    font-family: PingFang SC;
    word-wrap: break-word;
}

.event-details-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 36%;
    /* max-height: 90%; */
    padding: 32px 32px 40px 32px;
    background: #222222;
    border-radius: 4px;
    border: 2px rgba(255, 255, 255, 0.10) solid
}

.close-button {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 24px;
    width: 14px;
    height: 14px;
    cursor: pointer;
}

.title {
    margin-bottom: 24px;
}

.obj-name {
    display: inline-flex;
    margin-right: 13px;
    color: #CF80EB;
    font-size: 18px;
    font-weight: 500;
    justify-content: center;
    vertical-align: middle;
}

.obj-type {
    display: inline-block;
    margin: 3px 13px 3px 0;
    padding: 1px 8px;
    border: 1px solid #CF80EB;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 20px;
    color: #CF80EB;
    vertical-align: middle;
}

.obj-match {
    display: inline-block;
    padding: 1px 6px;
    color: white;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    border: 2px solid transparent;
    border-radius: 4px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #1e1e1e, #1e1e1e), linear-gradient(to right, #c486e4, #7b54f4);
    vertical-align: middle;
}

.details-container {
    max-height: 490px;
    overflow: auto;
}

.details-container::-webkit-scrollbar {
    width: 0;
}

.match-details-line {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
}

.match-details-left {
    display: inline-block;
    width: 70%;
}

.match-details-right {
    display: inline-block;
    width: 29.9%;
}

.show-name, .requests-headline {
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.match-rate-header {
    color: rgba(255, 255, 255, 0.60);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.match-key {
    display: flex;
    align-items: center;
    padding-right: 8px;
    color: white;
    font-size: 14px;
    font-weight: 400;
}

.match-key img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    vertical-align: middle;
}

.match-key span {
    vertical-align: middle;
}

.tag {
    display: inline-block;
    padding: 1px 8px;
    margin: 2px 0;
    border: 1px solid #CF80EB;
    border-radius: 2px;
    margin-right: 8px;
    color: #CF80EB;
    font-size: 12px;
    font-family: Roboto;
    line-height: 20px;
}

.cost {
    color: #CF80EB;
    font-size: 14px;
    font-weight: 500;
}

.requests-container {
    position: relative;
    margin-top: 32px;
}

.ai-summary {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
}

.ai-summary img {
    margin-right: 9px;
}

.ai-summary span {
    color: #CF80EB;
    font-size: 16px;
    line-height: 20px;
}

.request-item {
    margin-top: 12px;
    padding: 20px;
    border-radius: 4px;
    background-color: #1d1d1d;
}

.request-outline {
    margin-bottom: 8px;
    color: white;
    font-size: 16px;
    font-family: Roboto;
    line-height: 20px;
}

.request-content {
    color: rgba(255, 255, 255, 0.60);
    font-size: 14px;
    line-height: 1.5em;
}

.invite-button-container {
    margin-top: 24px;
    text-align: center;
}

.invite-button-container button {
    width: 200px;
    border: 0;
    border-radius: 4px;
    padding: 11px 16px;
    background: linear-gradient(to right, #c674e8, #7a41fd);
    color: white;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 500;
}

.get-invitation {
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    font-family: Roboto;
    margin-bottom: 8px;
    background-image: linear-gradient(to right, #c486e4, #7a42fd);
    color: transparent;
    background-clip: text;
}

.send-invitation-alert {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 16px;
    border-radius: 4px;
    background-color: rgba(133, 73, 253, 1);
    color: white;
    font-size: 14px;
    line-height: 22px;
}

.send-invitation-alert img {
    margin-right: 8px;
    vertical-align: middle;
}

.send-invitation-alert span {
    vertical-align: middle;
}
</style>
