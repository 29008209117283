<template>
    <div class="top-bar" v-if="topBarVisible">
        <div id="top-bar-container">
            <div id="top-bar-left">
                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="20.5" viewBox="0 0 100 30" fill="none" style="cursor: pointer;"
                @click="routeToDiary">
                    <path d="M16.0505 0H18.0078C23.3435 0 26.526 2.09091 27.5555 6.27272C27.9034 7.64986 28.0774 9.22854 28.0774 11.0087V29.2857H16.0505V0ZM0 11.0087C0 7.29718 0.732202 4.57648 2.19661 2.84666C3.806 0.948885 6.43033 0 10.0696 0H12.027V29.2857H0L0 11.0087Z"
                          fill="white"/>
                    <path d="M24.6276 13.8369C22.8279 13.8369 21.928 13.1851 21.928 11.8814C21.928 11.2139 22.1428 10.7352 22.5723 10.4455C23.0018 10.1495 23.657 10.0015 24.5378 10.0015C25.4187 10.0015 26.0684 10.1495 26.4871 10.4455C26.9057 10.7352 27.115 11.217 27.115 11.8909C27.115 12.5585 26.9193 13.0497 26.5278 13.3646C26.1418 13.6795 25.5084 13.8369 24.6276 13.8369ZM22.3847 16.4348H26.7725V26.0612H22.3847V16.4348Z"
                          fill="#942FFA"/>
                    <path d="M40.5937 12.1428H46.0309V17.8613H43.856C42.7613 17.8613 41.9168 17.538 41.3223 16.8914C40.8366 16.3624 40.5937 15.7074 40.5937 14.9265V12.1428ZM37.8534 29.2857C36.679 29.2857 35.6351 28.7819 34.7216 27.7742C33.9169 26.8841 33.2862 25.7001 32.8295 24.2222C32.409 22.8618 32.1988 21.5141 32.1988 20.1789C32.1988 18.172 32.6265 16.3876 33.4819 14.8257C34.0909 13.7173 34.892 12.9363 35.8852 12.4829C36.3926 12.2562 36.9581 12.1428 37.5816 12.1428H38.4189V29.2857H37.8534ZM40.0283 21.2748H46.0309V24.3482C46.0309 26.1452 45.6285 27.4131 44.8238 28.1521C44.0119 28.9078 42.6707 29.2857 40.8003 29.2857H40.0283V21.2748Z"
                          fill="white"/>
                    <path d="M56.6442 12.1428H62.6794V16.904H61.5485C59.5839 16.904 58.2427 16.5471 57.525 15.8334C56.9378 15.2456 56.6442 14.2967 56.6442 12.9867V12.1428ZM48.9126 12.1428H54.7629V19.0453H59.1235V22.3328H54.7629V29.2605H48.9126V12.1428ZM56.6333 28.0639C56.6333 26.3257 57.5033 25.1459 59.2431 24.5245C59.9101 24.2894 60.7692 24.1718 61.8204 24.1718H62.6794V29.2857H56.6333V28.0639Z"
                          fill="white"/>
                    <path d="M73.4124 12.1428H74.391C77.0589 12.1428 78.6501 13.1883 79.1648 15.2792C79.3388 15.9677 79.4258 16.7571 79.4258 17.6472V29.2857H73.4124V12.1428ZM65.5176 12.1428H71.368V29.2857H65.5176V12.1428Z"
                          fill="white"/>
                    <path d="M95.1283 12.1428H100V19.2594H98.7821C97.9846 19.2594 97.3974 19.1629 97.0204 18.9697C96.6435 18.7682 96.3462 18.5457 96.1287 18.3022C95.9185 18.0502 95.7373 17.7227 95.585 17.3197C95.2806 16.4968 95.1283 15.3547 95.1283 13.8936V12.1428ZM88.0926 12.1428H93.943V29.2857H88.0926V12.1428ZM82.003 12.1428H86.8747V13.8936C86.8747 16.0517 86.5376 17.5212 85.8634 18.3022C85.3125 18.9403 84.4316 19.2594 83.221 19.2594H82.003V12.1428Z"
                          fill="white"/>
                </svg>
            </div>
            <div class="top-bar-right" v-show="showUser && !showExitBtn">
                <div style="display: inline-flex; align-items: center; cursor: pointer;"
                @click="routeToFill">
                    <div style="display: inline-block; position: relative;">
                        <img class="avatar" :src="avatarURL" />
                        <img src="../assets/vertified-icon.svg" style="position: absolute; width: 10px; height: 10px; bottom: 0; right: 0;" 
                        v-show="hasEnterpriseLicense"/>
                    </div>
                    <span id="user-name">{{ userName }}</span>
                </div>
            </div>

            <div class="top-bar-right" v-show="showExitBtn">
                <span style="color: white; font-size: 14px; font-family: PingFang SC; font-weight: 400; cursor: pointer;" 
                @click="showExitDialog = true">
                    Log out
                </span>
            </div>

            <div class="exit-container"
            v-show="showExitDialog">
                <img class="close-button" src="../assets/Close.svg"
                @click="showExitDialog = false"/>

                <div style="color: white; font-size: 21px; font-family: PingFang SC; font-weight: 400;">Are you sure to log out?</div>

                <div style="margin-top: 50px; text-align: center;">
                    <button class="cancel-button"
                    @click="showExitDialog = false"><span>Cancel</span></button>

                    <button class="log-out-button"
                    @click="logoutHandler">Log out</button>
                </div>
            </div>
        </div>
		<div id="light-box-left"></div>
        <div id="light-box-right"></div>
    </div>
</template>

<script>
    import { user, API_HOST } from "../js/global.js";
    import { RESP_CODE, USER_ROLE } from "../functions/commonUtil.js";
    import { STORAGE_NAME, getFilePath, getDownloadURLByPath} from "../functions/fileUpDownload.js";
    import { QueryUserInfoRequestParams, QUERY_USER_INFO_TYPE } from "@/functions/queryUserInfoParams.js";

    export default {
        name: "TopBar",
        props: {
            topBarVisible: Boolean
        },
        data() {
            return {
                userName: null,
                avatarURL: null,
                showUser: false,
                hasEnterpriseLicense: false,
                showExitBtn: false,
                showExitDialog: false,
            }
        },
        methods: {
            queryUserInfo() {
                const queryUserInfoReq = new QueryUserInfoRequestParams(user.uid, QUERY_USER_INFO_TYPE.ALL_INFO);
                const cgiUrl = API_HOST + '/queryUserInfo';
                const body = JSON.stringify(queryUserInfoReq);
                fetch(cgiUrl, {
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    console.log("结果：", response);
                    if (!response.ok) {
                        console.log(cgiUrl, '失败，错误码：' + response.status);
                        return;
                    }
                    return response.json(); // 解析JSON响应;
                })
                .then((responseData) => {
                    console.log('数据:', responseData);
                    if (responseData.code == RESP_CODE.SUCCESS) {
                        if (responseData.user_role == USER_ROLE.COMPANY && responseData.cert_file) {
                            this.hasEnterpriseLicense = true;
                        }
                        const path = getFilePath(user.uid, STORAGE_NAME.AVATAR, responseData.avatar);
                        getDownloadURLByPath(path).then((url) => {
                            this.avatarURL = url;
                        });
                        this.userName = responseData.name;
                        this.showUser = true;
                    }
                })
                .catch((error) => {
                    console.log(cgiUrl, "失败：", error);
                });
            },

            showExit(isShow) {
                this.showExitBtn = isShow;
            },

            routeToDiary() {
                if (!user.isFirstFill) {
                    this.$router.push("diary");
                }
            },

            routeToFill() {
                if (user.userRole == USER_ROLE.MUSICIAN) {
                    this.$router.push("fill-personal-info");
                }
                else if (user.userRole == USER_ROLE.COMPANY) {
                    this.$router.push("fill-company-info");
                }
            },

            logoutHandler() {
                document.cookie = "uid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                user.uid = null;
                user.userRole = -1;
                user.name = null;
                user.isFirstFill = true;
                this.$router.push("login");
            }
        },
        created() {
            const url = window.location.href;
            if (url.includes("/fill-company-info") || url.includes("/fill-personal-info")) {
                this.showExitBtn = true;
            }
            this.queryUserInfo();
        }
    }
</script>

<style scoped>
    .top-bar {
        position: absolute;
        top: 0;
        width: 100%;
        height: 48px;
        /*flex-shrink: 0;*/
        background: black;
    }

    #top-bar-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        background: black;
    }

    #top-bar-left {
        position: absolute;
        left: 0;
        width: 50%;
        height: 100%;
        padding: 12.5px 0 15px 31px;
    }

    .top-bar-right{
        position: absolute;
        right: 0;
        width: 50%;
        height: 100%;
        padding: 14px 32px 14px 0;
        display: flex;
        justify-content: right;
        align-items: center;
    }

    #light-box-left {
        position: absolute;
        left: 0;
        width: 50%;
        height: 100%; 
        box-shadow: 0px 4px 100px rgba(239, 93, 168, 0.50);
        z-index: 1;
    }

    #light-box-right {
        position: absolute;
        right: 0;
        width: 50%;
        height: 100%;
        box-shadow: 0px 4px 100px rgba(69, 197, 237, 0.50);
        z-index: 1;
    }

    .avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
    }

    .avatar img {
        width: 100%;
        height: 100%;
    }

    #user-name {
        color: white;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        margin-left: 8px;
    }

    .exit-container {
        position: fixed; 
        z-index: 10;
        width: 480px;
        padding: 50px 0 40px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid #333333;
        border-radius: 4px;
        background: #222222;
        text-align: center;
    }

    .close-button {
        display: inline-block;
        position: absolute;
        top: 20px;
        right: 24px;
        width: 14px;
        height: 14px;
        cursor: pointer;
    }

    .cancel-button {
        width: 100px;
        height: 41px;
        padding: 4px 12px;
        border: 1.5px solid transparent;
        border-radius: 4px;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        background-image: linear-gradient(to right, #222222, #222222), linear-gradient(to right, #c486e4, #7b54f4);
        cursor: pointer;
    }

    .cancel-button span {
        background-image: linear-gradient(to right, #c486e4, #7b54f4);
        color: transparent;
        background-clip: text;
        color: #8549FD;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 500;
    }

    .log-out-button {
        width: 100px;
        height: 41px;
        padding: 11px 16px;
        margin-left: 12px;
        border: 0px;
        border-radius: 4px;
        background-image: linear-gradient(to right, #c486e4, #7b54f4);
        color: white;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 500;
        cursor: pointer;
    }
</style>