import {createRouter, createWebHistory} from 'vue-router'
import MyCalendarPage from '../pages/MyCalendarPage.vue'
import LoginPage from '../pages/LoginPage.vue'
import AiCommunication from '../components/AiCommunication.vue'
import IdentitySelectPage from '../pages/IdentitySelectPage.vue'
import EmailAuthorizationPage from '../pages/EmailAuthorizationPage.vue'
import DataUploadPage from '../pages/DataUploadPage.vue'
import FillCompanyInfoPage from '../pages/FillCompanyInfoPage.vue'
import FillPersonalInfoPage from '../pages/FillPersonalInfoPage.vue'
import UploadFile from '../components/UploadFile.vue'
import TestPage from '../pages/TestPage.vue'

const routes = [
    {path: '/diary', component: MyCalendarPage},
    {path: '/login', component: LoginPage},
    {path: '/ai', component: AiCommunication},
    {path: '/identity-select', component: IdentitySelectPage},
    {path: '/email-auth', component: EmailAuthorizationPage},
    {path: '/data-upload', component: DataUploadPage},
    {path: '/fill-company-info', component: FillCompanyInfoPage},
    {path: '/fill-personal-info', component: FillPersonalInfoPage},

    {path: '/uploadfile', component: UploadFile},

    // {path: '/test', component: TestPage},

    {path: '/', component: LoginPage},
    {path: '', component: LoginPage},
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router