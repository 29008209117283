import { BaseRequestParams, BaseResponseParams } from "./commonUtil";

/*
    请求
    * @param {string} uid - 用户uid
*/
export class UpdateEventRequestParams extends BaseRequestParams {
    constructor(uid) {
        super(uid);

        this.event_id = null; //日程id, 空表示新增，非空表示修改

        this.event_name = null; //日程名
        this.event_start_date = null; //开始日期，当天0点0时区时间戳
        this.event_end_date = null; //结束日期，当天0点0时区时间戳
        this.event_times = null; //时间点, list，每个元素为EVENT_TIME，null表示不限
        this.event_location = null; //地点, list，每个元素为一个城市，null表示不限
        this.event_reward_lower = null; //最低报酬，int
        this.event_reward_upper = null; //最高报酬，int
        this.event_description = null; //描述, string, null表示不限

        this.event_music_style = null; //音乐风格, list，每个元素为一个风格，null表示不限
    }
}

/*
    响应
    * @param {int} code - 响应码, RESP_CODE
    * @param {string} message - 响应消息
    * @param {string} event_id - 日程id
*/
export class UpdateEventResponseParams extends BaseResponseParams {
    constructor(code, event_id) {
        super(code);
        this.event_id = event_id;
    }
}