import { BaseRequestParams } from "./commonUtil";

/*
    请求
    * @param {string} uid - 用户uid
    * @param {string} event_id - 个人日程id
    * @param {string} matched_event_id - 匹配的日程id
    * @param {int} match_status - 匹配状态, MATCH_STATUS，只能传
    *       INVITING: 3, //邀请他人
    *       IGNORED: 4, //忽略，拒绝
    *       ACCEPTED: 5, //接受
*/
export class UpdateMatchStatusRequestParams extends BaseRequestParams {
    constructor(uid, event_id, matched_event_id, match_status) {
        super(uid);
        this.event_id = event_id;
        this.matched_event_id = matched_event_id;
        this.match_status = match_status;
        this.attachment = null; //附件，可选字段，ACCEPTED的时候带上
    }
}