<template>
    <div class="data-upload-background-div"></div>
    
    <div class="data-upload-container">
        <div class="data-upload-name">{{ getTitleMsg() }}</div>
        <div class="data-upload-hint">Let's know more about you</div>
        <div class="data-upload-gmail" @click="goToFillInfoPage()">
            <div class="data-upload-gmail-text">{{ getButtonMsg() }}</div>
        </div>
    </div>
</template>

<script>
    import { user } from "../js/global.js";
    import { USER_ROLE } from "../functions/commonUtil.js";

    export default {
        name: "DataUploadPage",
        methods: {
            getTitleMsg() {
                if (user.userRole == USER_ROLE.MUSICIAN) {
                    return "You have created your personal account";
                }
                else {
                    return "You have created your company account";
                }
            },
            getButtonMsg() {
                if (user.userRole == USER_ROLE.MUSICIAN) {
                    return "Fill in Musician Info";
                }
                else {
                    return "Fill in Company Info";
                }
            },
            goToFillInfoPage() {
                if (user.userRole == USER_ROLE.COMPANY) {
                    this.$router.push("fill-company-info");
                }
                else if (user.userRole == USER_ROLE.MUSICIAN) {
                    this.$router.push("fill-personal-info");
                }
            }
        }
    }
</script>

<style scoped>
    .data-upload-gmail-text {
        padding-left: 8px;
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .data-upload-gmail {
        /* width: 200px; */
        display: inline-flex;
        padding: 10px 45px 10px 44px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: linear-gradient(270deg, #8549FD 0%, #9E5BF7 32.29%, #CF80EB 100%);
        text-align: center;
        margin: 0 auto;
    }

    .data-upload-gmail:hover {
        cursor: pointer;
    }
    
    .data-upload-hint {
        color: #FFF;
        font-family: PingFang SC;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-bottom: 48px;
        text-align: center;
    }
    
    .data-upload-name {
        text-align: center;
        padding-top: 297px;
        padding-bottom: 26px;
        font-family: PingFang SC;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: linear-gradient(270deg, #8549FD 0%, #9E5BF7 32.29%, #CF80EB 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    .data-upload-container {
        display: grid;
        justify-content: center;
        grid-column-start: 1;
    }
    
    .data-upload-background-div {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-color: black;
    }
</style>